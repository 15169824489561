/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Wodegongshi: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M874.666667 682.666667a64 64 0 0 1 64 64v21.333333c0 84.096-79.36 170.666667-213.333334 170.666667-133.973333 0-213.333333-86.570667-213.333333-170.666667v-21.333333a64 64 0 0 1 64-64zM853.333333 320V426.666667a160 160 0 1 0-236.8 213.333333h-40.533333a106.666667 106.666667 0 0 0-106.666667 106.666667v21.333333c0 29.866667 7.253333 58.837333 20.821334 85.333333H224A138.666667 138.666667 0 0 1 85.333333 714.666667V320h768z m-128 85.333333a117.333333 117.333333 0 1 1 0 234.666667 117.333333 117.333333 0 0 1 0-234.666667zM714.666667 85.333333A138.666667 138.666667 0 0 1 853.333333 224V256H85.333333v-32A138.666667 138.666667 0 0 1 224 85.333333h490.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Wodegongshi.defaultProps = {
  size: 18,
};
*/
export default Wodegongshi;
