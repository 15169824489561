/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBookToolbox24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M275.584 170.666667C217.642667 170.666667 170.666667 218.453333 170.666667 277.333333v412.458667c0 58.88 46.933333 106.666667 104.917333 106.666667h174.848c0-14.762667 3.754667-29.525333 11.221333-42.666667H275.584a63.488 63.488 0 0 1-62.933333-64V355.541333h598.698666v136.576a40.106667 40.106667 0 0 1 1.28 0.426667c12.288 3.2 33.408 20.778667 39.210667 39.381333H853.333333V277.333333C853.333333 218.453333 806.4 170.666667 748.416 170.666667H275.584z m536.106667 142.208H213.333333V277.333333C213.333333 242.005333 241.493333 213.333333 276.224 213.333333H748.8c34.773333 0 62.933333 28.672 62.933333 64v35.541334z m-180.650667 186.197333a147.328 147.328 0 0 1 142.933333-5.162667 18.858667 18.858667 0 0 1 4.736 30.122667l-70.272 69.589333 26.965334 26.666667L805.973333 550.4a19.2 19.2 0 0 1 30.293334 4.48 143.530667 143.530667 0 0 1-13.653334 156.416 146.901333 146.901333 0 0 1-149.546666 52.053333l-61.994667 66.56a78.08 78.08 0 0 1-109.610667 1.834667 76.245333 76.245333 0 0 1-1.877333-108.544l64.853333-69.461333a144.384 144.384 0 0 1 66.56-154.666667z m-10.666667 59.861333a106.112 106.112 0 0 0-16.554666 94.848 18.773333 18.773333 0 0 1-4.522667 18.602667l-72.277333 77.056a38.784 38.784 0 0 0 0.938666 55.168 39.68 39.68 0 0 0 55.765334-0.896l69.888-74.666667a19.2 19.2 0 0 1 19.712-4.821333 108.8 108.8 0 0 0 107.008-22.869333 106.325333 106.325333 0 0 0 31.872-103.722667l-63.317334 62.72a19.2 19.2 0 0 1-26.965333 0l-53.888-53.376a18.773333 18.773333 0 0 1 0-26.709333l62.634667-62.08a108.373333 108.373333 0 0 0-110.336 40.704z"
        fill={getIconColor(color, 0, '#2c2c2c')}
      />
      <path
        d="M280.192 450.858667a24.362667 24.362667 0 0 1 34.474667 0l97.536 97.536a24.405333 24.405333 0 0 1 0 34.474666l-97.536 97.536a24.405333 24.405333 0 0 1-34.474667-34.474666l80.298667-80.298667-80.298667-80.256a24.362667 24.362667 0 0 1 0-34.517333zM507.136 638.762667h-73.130667a24.362667 24.362667 0 0 0 0 48.768h73.130667a24.362667 24.362667 0 0 0 0-48.768z"
        fill={getIconColor(color, 1, '#2c2c2c')}
      />
    </svg>
  );
};
/*
IcFluentBookToolbox24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentBookToolbox24Regular;
