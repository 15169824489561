/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCalendarClock24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M696.362667 454.058667a242.346667 242.346667 0 1 1 0 484.608 242.346667 242.346667 0 0 1 0-484.608z m0 59.733333c-100.693333 0-182.613333 81.92-182.613334 182.570667 0 100.693333 81.92 182.613333 182.613334 182.613333 100.693333 0 182.613333-81.92 182.613333-182.613333 0-100.693333-81.92-182.613333-182.613333-182.613334zM706.901333 85.333333a136.96 136.96 0 0 1 136.96 136.96v243.2a272.682667 272.682667 0 0 0-63.232-29.866666V317.098667H148.522667v389.802666c0 40.704 33.024 73.728 73.770666 73.728h213.333334c7.253333 22.272 17.28 43.52 29.866666 63.232h-243.2A136.96 136.96 0 0 1 85.333333 706.901333V222.293333A136.96 136.96 0 0 1 222.293333 85.333333z m-27.904 461.44c13.952 0 25.728 10.282667 27.733334 24.832l0.170666 3.072-0.042666 111.104 64.853333 0.042667c13.226667 0 24.362667 9.216 27.178667 21.76l0.64 4.266667a28.074667 28.074667 0 0 1-24.746667 29.610666l-3.114667 0.213334h-92.672a28.074667 28.074667 0 0 1-27.733333-24.832l-0.213333-3.114667V574.72c0-15.402667 12.501333-27.904 27.946666-27.904z m27.904-398.250666H222.293333c-40.704 0-73.770667 33.066667-73.770666 73.770666v31.573334h632.106666v-31.573334c0-40.704-33.024-73.770667-73.728-73.770666z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentCalendarClock24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentCalendarClock24Regular;
