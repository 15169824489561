/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentNews24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M800 853.333333H224a138.666667 138.666667 0 0 1-138.453333-130.816L85.333333 714.666667V266.666667a96 96 0 0 1 89.429334-95.786667L181.333333 170.666667h533.333334a96 96 0 0 1 95.786666 89.429333L810.666667 266.666667V298.666667h32a96 96 0 0 1 95.786666 89.429333L938.666667 394.666667v320a138.666667 138.666667 0 0 1-130.816 138.453333L800 853.333333H224h576z m-576-64h576a74.666667 74.666667 0 0 0 74.410667-68.565333l0.256-6.101333v-320a32 32 0 0 0-27.648-31.701334L842.666667 362.666667H810.666667v330.666666a32 32 0 0 1-27.648 31.701334L778.666667 725.333333a32 32 0 0 1-31.701334-27.648l-0.298666-4.352v-426.666666a32 32 0 0 0-27.648-31.701334L714.666667 234.666667H181.333333a32 32 0 0 0-31.701333 27.648L149.333333 266.666667v448a74.666667 74.666667 0 0 0 68.522667 74.410666l6.144 0.256h576-576z m298.496-170.666666h128.256a32 32 0 0 1 4.352 63.701333l-4.352 0.298667h-128.256a32 32 0 0 1-4.352-63.701334l4.352-0.298666h128.256-128.256z m-128.128-149.12a32 32 0 0 1 32 32v149.12a32 32 0 0 1-32 32H245.248a32 32 0 0 1-32-32v-149.12a32 32 0 0 1 32-32h149.12z m-32 64H277.248V618.666667h85.12v-85.12z m160.128-64h128.256a32 32 0 0 1 4.352 63.701333l-4.352 0.298667h-128.256a32 32 0 0 1-4.352-63.744l4.352-0.256h128.256-128.256zM245.248 320.085333h405.504a32 32 0 0 1 4.352 63.744l-4.352 0.256H245.248a32 32 0 0 1-4.352-63.701333l4.352-0.298667h405.504-405.504z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentNews24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentNews24Regular;
