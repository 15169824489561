/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Archive24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M853.333333 341.333333v490.666667a106.666667 106.666667 0 0 1-106.666666 106.666667h-469.333334A106.666667 106.666667 0 0 1 170.666667 832V341.333333h682.666666z m-256 149.333334h-170.666666a32 32 0 0 0 0 64h170.666666a32 32 0 0 0 0-64zM853.333333 128a42.666667 42.666667 0 0 1 42.666667 42.666667v85.333333a42.666667 42.666667 0 0 1-42.666667 42.666667H170.666667a42.666667 42.666667 0 0 1-42.666667-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667h682.666666z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
Archive24Filled.defaultProps = {
  size: 18,
};
*/
export default Archive24Filled;
