/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Dingding02: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M768 426.666667a128 128 0 0 1-8.106667 21.333333c-23.893333 50.773333-85.333333 151.04-85.333333 151.04l-17.92 31.573333h87.466667L575.573333 853.333333l37.973334-151.893333h-69.12l23.893333-100.266667c-19.2 4.693333-42.666667 11.093333-69.12 20.053334 0 0-36.693333 21.333333-105.813333-42.666667 0 0-46.933333-42.666667-19.626667-51.626667a837.12 837.12 0 0 1 90.453333-14.933333l76.373334-9.813333s-145.493333 0-180.053334-3.413334-78.08-62.72-85.333333-113.493333c0 0-14.08-27.733333 31.146667-14.506667s232.96 51.2 232.96 51.2-246.186667-73.386667-262.4-91.733333a290.133333 290.133333 0 0 1-42.666667-149.76 11.52 11.52 0 0 1 13.653333-9.813333S426.666667 256 551.68 298.666667s230.826667 68.266667 216.746667 128z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Dingding02.defaultProps = {
  size: 18,
};
*/
export default Dingding02;
