/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCalendarEdit24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M804.656 496.064A109.728 109.728 0 0 1 959.84 651.2l-283.248 283.344a128.88 128.88 0 0 1-59.904 33.888l-87.84 21.984a52.176 52.176 0 0 1-63.264-63.312l21.936-87.84c5.664-22.656 17.424-43.392 33.936-59.904l283.248-283.248zM895.904 296v120.528a157.392 157.392 0 0 0-125.136 45.6l-77.472 77.376-0.432-4.368a201.648 201.648 0 1 0-218.88 225.6c-15.648 19.584-26.88 42.384-33.024 66.864l-17.088 68.304H188A156 156 0 0 1 32 739.904V296h863.904zM492.8 424.928a135.264 135.264 0 0 1 130.56 169.92l-5.376 19.968-70.32 70.32-20.112 5.424A135.264 135.264 0 0 1 357.68 560 135.264 135.264 0 0 1 492.8 424.928z m-13.776 11.712a26.88 26.88 0 0 0-26.88 26.928v110.208l0.096 2.736c1.92 14.16 13.248 24.192 26.784 24.192h73.44l2.736-0.144c14.88-2.016 25.104-14.4 24.144-28.608l-0.528-3.792-1.44-4.512a26.88 26.88 0 0 0-24.864-16.8l-46.608-0.048V463.568l-0.096-2.736a27.12 27.12 0 0 0-26.784-24.192zM739.904 32a156 156 0 0 1 156 156V224H32v-36A156 156 0 0 1 188 32z"
        fill={getIconColor(color, 0, '#707070')}
      />
    </svg>
  );
};
/*
IcFluentCalendarEdit24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentCalendarEdit24Filled;
