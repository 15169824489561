/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Regular2: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M554.666667 85.504a127.914667 127.914667 0 0 1 127.744 120.32l0.213333 7.466667L778.666667 213.333333c36.992 0 67.712 26.88 73.642666 62.250667l0.768 6.314667 0.256 6.101333v160.042667h-85.248a64 64 0 0 0-63.146666 52.906666l-0.682667 5.632-0.213333 5.461334a64 64 0 0 0 52.906666 63.146666l5.632 0.64 5.461334 0.213334H853.333333L853.333333 736.128a74.666667 74.666667 0 0 1-68.565333 74.410667l-6.101333 0.256-96.042667-0.042667-0.170667 7.381333a128 128 0 0 1-112.554666 119.552l-7.68 0.682667-7.552 0.213333a128 128 0 0 1-127.786667-120.32L426.666667 810.752H330.666667a74.666667 74.666667 0 0 1-73.642667-62.208l-0.768-6.314667L256 736.128 255.957333 640l-6.954666-0.213333a128 128 0 0 1-119.594667-112.554667l-0.682667-7.68L128.512 512a128 128 0 0 1 120.490667-127.786667l6.954666-0.213333L256 288c0-36.992 26.88-67.712 62.208-73.642667l6.314667-0.768L330.666667 213.333333l95.957333-0.042666 0.256-7.296a128 128 0 0 1 112.554667-119.594667l7.68-0.682667 7.552-0.213333z m0 64a64 64 0 0 0-63.701334 57.856l-0.298666 6.186667-0.085334 63.744L330.666667 277.333333a10.666667 10.666667 0 0 0-10.368 8.234667L320 288v160.042667H256.469333a64 64 0 0 0-6.144 127.701333l6.144 0.298667H320l0.042667 160.085333a10.666667 10.666667 0 0 0 8.234666 10.368l2.432 0.298667h159.914667l0.085333 63.786666a64 64 0 0 0 127.701334 6.186667L618.666667 810.666667l-0.085334-63.872h160.085334a10.666667 10.666667 0 0 0 10.368-8.192l0.298666-2.432L789.290667 640h-22.314667l-7.68-0.256a128.128 128.128 0 0 1-119.04-121.173333l-0.170667-7.594667 0.256-7.68a128 128 0 0 1 120.277334-119.04l7.424-0.213333h21.248L789.333333 288a10.666667 10.666667 0 0 0-5.546666-9.386667l-2.688-0.981333-2.432-0.298667h-160.085334l0.085334-63.829333a64 64 0 0 0-64-64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
A24Regular2.defaultProps = {
  size: 18,
};
*/
export default A24Regular2;
