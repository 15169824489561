/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Anquanguanli: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M864 213.333333c-113.621333 0-224.341333-40.234667-332.8-121.6a32 32 0 0 0-38.4 0C384.341333 173.098667 273.621333 213.333333 160 213.333333a32 32 0 0 0-32 32V469.333333c0 213.376 126.208 370.176 372.266667 467.114667a32 32 0 0 0 23.466666 0C769.792 839.509333 896 682.666667 896 469.333333V245.333333a32 32 0 0 0-32-32z m-149.034667 183.594667l-256 234.666667a32 32 0 0 1-44.245333-0.981334l-106.666667-106.666666a32 32 0 1 1 45.226667-45.226667l85.034667 84.992 233.386666-213.973333a32 32 0 0 1 43.264 47.189333z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
Anquanguanli.defaultProps = {
  size: 18,
};
*/
export default Anquanguanli;
