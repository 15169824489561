/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Banbenfenfa: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 939.5C275.9075 939.5 84.5 748.0925 84.5 512S275.9075 84.5 512 84.5s427.5 191.4075 427.5 427.5-191.4075 427.5-427.5 427.5zM283.9625 341.045l188.37 398.9925h78.3l189.315-398.9925h-84.96L512 662.795l-142.9875-321.75h-85.05z"
        fill={getIconColor(color, 0, '#2c2c2c')}
      />
    </svg>
  );
};
/*
Banbenfenfa.defaultProps = {
  size: 18,
};
*/
export default Banbenfenfa;
