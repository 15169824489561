/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shenchafenpei: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M860.38709698 526.51612901c32.08064502 0 58.06451602 26.41935498 58.06451601 59.05161298 0 32.60322597-25.98387099 59.05161299-58.06451602 59.05161299a57.77419395 57.77419395 0 0 1-48.6-26.70967793h-140.516129c-22.38387099 0-40.64516104 18.58064502-40.64516191 41.57419394 0 20.43870997 14.45806494 37.53870997 33.0967749 40.93548399l3.8032251 0.49354804 1.24838789 0.02903204H816.83870996c1.48064502 0 2.99032295 0.05806494 4.47096709 0.11612988h9.63870996v0.87096709c51.09677402 6.967742 90.40645195 51.38709697 90.40645195 105.06774199 0 54.40645195-40.5 99.60967705-93.48387099 105.44516104l-5.51612901 0.43548398-5.516129 0.17419395h-140.05161299a57.77419395 57.77419395 0 0 1-48.65806494 26.79677402c-32.08064502 0-58.06451602-26.41935498-58.06451601-59.05161298 0-32.60322597 25.98387099-59.05161299 58.06451601-59.05161299 20.38064502 0 38.32258096 10.68387099 48.65806494 26.85483896H816.83870996c22.38387099 0 40.64516104-18.58064502 40.64516103-41.60322597 0-20.43870997-14.45806494-37.53870997-33.09677402-40.93548399l-3.80322598-0.49354804-1.27741904-0.05806495h-148.03548398c-1.48064502 0-2.96129003 0-4.44193594-0.08709609H657.16129004v-0.87096797c-51.09677402-6.967742-90.40645195-51.41612901-90.40645108-105.09677403 0-54.37741904 40.5-99.58064502 93.48387101-105.38709696l5.51612899-0.46451602 5.51612901-0.17419395h140.4A57.77419395 57.77419395 0 0 1 860.38709698 526.51612901z m-333.52258096 29.032258C487.90322597 649.64193506 468.45161299 718.56451602 468.45161299 762.25806494c0 54.60967705 11.20645195 107.59354805 32.632258 156.36774112l9.14516104 20.78709697H279.74193506c-104.22580606 0-188.70967705-85.93548398-188.70967706-191.90322598 0-105.99677403 84.48387099-191.90322597 188.70967706-191.9032251zM541.032258 62C661.28387099 62 758.77419395 161.14516104 758.77419395 283.42903203s-97.49032295 221.42903203-217.74193594 221.42903291c-120.25161299 0-217.74193506-99.14516104-217.74193506-221.42903291S420.78064502 62 541.032258 62z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Shenchafenpei.defaultProps = {
  size: 18,
};
*/
export default Shenchafenpei;
