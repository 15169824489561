/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconSousuo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M970.0352 906.5728L670.848 607.3856A342.5024 342.5024 0 0 0 742.4 396.8a343.552 343.552 0 0 0-101.2736-244.3264A343.04 343.04 0 0 0 396.8 51.2a343.552 343.552 0 0 0-244.3264 101.248A342.8608 342.8608 0 0 0 51.2 396.8a343.552 343.552 0 0 0 101.248 244.3264A342.8608 342.8608 0 0 0 396.8 742.4a342.784 342.784 0 0 0 210.4576-71.424l299.1872 299.0592a9.472 9.472 0 0 0 13.3632 0l50.2272-50.1248a9.472 9.472 0 0 0 0-13.3376zM579.2768 579.2768A256.6656 256.6656 0 0 1 396.8 654.848c-68.8896 0-133.632-26.8288-182.4768-75.5712A256.6656 256.6656 0 0 1 138.752 396.8c0-68.8896 26.8288-133.76 75.5712-182.4768A256.6656 256.6656 0 0 1 396.8 138.752a256 256 0 0 1 182.4768 75.5712A256.6656 256.6656 0 0 1 654.848 396.8c0 68.8896-26.8288 133.7344-75.5712 182.4768z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconSousuo.defaultProps = {
  size: 18,
}

export default IconSousuo
