/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentAlbum24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M426.666667 384a85.333333 85.333333 0 0 1 85.333333-85.333333h170.666667a85.333333 85.333333 0 0 1 85.333333 85.333333v42.666667a85.333333 85.333333 0 0 1-85.333333 85.333333h-170.666667a85.333333 85.333333 0 0 1-85.333333-85.333333V384z m85.333333-21.333333a21.333333 21.333333 0 0 0-21.333333 21.333333v42.666667a21.333333 21.333333 0 0 0 21.333333 21.333333h170.666667a21.333333 21.333333 0 0 0 21.333333-21.333333V384a21.333333 21.333333 0 0 0-21.333333-21.333333h-170.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M170.666667 170.666667a85.333333 85.333333 0 0 0-85.333334 85.333333v512a85.333333 85.333333 0 0 0 85.333334 85.333333h682.666666a85.333333 85.333333 0 0 0 85.333334-85.333333V256a85.333333 85.333333 0 0 0-85.333334-85.333333H170.666667z m682.666666 64a21.333333 21.333333 0 0 1 21.333334 21.333333v512a21.333333 21.333333 0 0 1-21.333334 21.333333H320v-554.666666H853.333333z m-682.666666 0h85.333333v554.666666H170.666667a21.333333 21.333333 0 0 1-21.333334-21.333333V256a21.333333 21.333333 0 0 1 21.333334-21.333333z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentAlbum24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentAlbum24Regular;
