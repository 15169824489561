/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentGrid24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M373.333333 554.666667A96 96 0 0 1 469.333333 650.666667v149.333333A96 96 0 0 1 373.333333 896h-149.333333A96 96 0 0 1 128 800v-149.333333A96 96 0 0 1 224 554.666667h149.333333z m426.666667 0A96 96 0 0 1 896 650.666667v149.333333A96 96 0 0 1 800 896h-149.333333A96 96 0 0 1 554.666667 800v-149.333333A96 96 0 0 1 650.666667 554.666667h149.333333z m-426.666667-426.666667A96 96 0 0 1 469.333333 224v149.333333A96 96 0 0 1 373.333333 469.333333h-149.333333A96 96 0 0 1 128 373.333333v-149.333333A96 96 0 0 1 224 128h149.333333z m426.666667 0A96 96 0 0 1 896 224v149.333333A96 96 0 0 1 800 469.333333h-149.333333A96 96 0 0 1 554.666667 373.333333v-149.333333A96 96 0 0 1 650.666667 128h149.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentGrid24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentGrid24Filled;
