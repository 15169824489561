/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentWallet24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M693.333333 554.666667a32 32 0 0 0 0 64h85.333334a32 32 0 0 0 0-64h-85.333334z m128-309.333334A117.333333 117.333333 0 0 0 704 128H224a96 96 0 0 0-95.744 102.784A32.298667 32.298667 0 0 0 128 234.666667v522.666666A138.666667 138.666667 0 0 0 266.666667 896h533.333333a117.333333 117.333333 0 0 0 117.333333-117.333333v-405.333334a117.333333 117.333333 0 0 0-96-115.413333V245.333333zM192 224a32 32 0 0 1 32-32H704c29.44 0 53.333333 23.893333 53.333333 53.333333V256H224a32 32 0 0 1-32-32z m32 96h576c29.44 0 53.333333 23.893333 53.333333 53.333333v405.333334c0 29.44-23.893333 53.333333-53.333333 53.333333H266.666667a74.666667 74.666667 0 0 1-74.666667-74.666667V314.538667c10.026667 3.541333 20.778667 5.461333 32 5.461333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentWallet24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentWallet24Regular;
