/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentEarth24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 85.290667c235.690667 0 426.752 191.061333 426.752 426.752 0 235.648-191.061333 426.709333-426.752 426.709333-235.690667 0-426.752-191.061333-426.752-426.709333C85.248 276.352 276.309333 85.290667 512 85.290667zM637.397333 704H386.56c27.818667 102.997333 76.202667 170.752 125.397333 170.752s97.578667-67.754667 125.397334-170.752z m-317.013333 0H204.16a364.032 364.032 0 0 0 174.677333 145.536c-22.272-34.986667-40.661333-78.762667-54.186666-128.64l-4.352-16.853333z m499.413333 0h-116.138666c-13.824 56.96-33.792 106.666667-58.581334 145.536a363.861333 363.861333 0 0 0 166.826667-133.418667l7.893333-12.074666zM302.677333 426.666667H159.36l-0.213333 0.725333a363.733333 363.733333 0 0 0-9.941334 84.650667c0 45.056 8.234667 88.192 23.253334 128h135.381333a889.472 889.472 0 0 1-5.248-213.333334z m354.261334 0H367.061333a809.216 809.216 0 0 0 5.76 213.333333h278.357334a809.557333 809.557333 0 0 0 5.76-213.333333z m207.701333 0h-143.274667c2.645333 27.605333 4.053333 56.192 4.053334 85.333333a866.133333 866.133333 0 0 1-9.301334 128h135.381334a361.898667 361.898667 0 0 0 23.210666-128c0-29.397333-3.498667-58.026667-10.069333-85.333333zM378.88 174.506667l-0.981333 0.341333A363.989333 363.989333 0 0 0 181.333333 362.666667h130.048c13.397333-74.752 36.693333-139.861333 67.541334-188.16zM512 149.290667l-4.949333 0.213333C453.12 154.453333 400.896 239.872 376.746667 362.666667h270.592c-24.149333-122.453333-76.074667-207.744-129.92-213.12L512 149.333333z m133.12 25.173333l4.565333 7.466667c28.544 47.445333 50.218667 109.738667 62.933334 180.778666h130.048a364.074667 364.074667 0 0 0-185.130667-183.04l-12.416-5.162666z"
        fill={getIconColor(color, 0, '#515151')}
      />
    </svg>
  );
};
/*
IcFluentEarth24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentEarth24Regular;
