/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPersonAccounts24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M181.290667 597.12h250.709333a117.034667 117.034667 0 0 0-26.453333 65.28l-0.384 9.386667v192c0 28.373333 10.069333 54.4 26.88 74.666666l-5.546667 0.042667c-145.92 0-253.568-45.738667-319.402667-138.069333A117.333333 117.333333 0 0 1 85.333333 732.330667v-39.253334a95.957333 95.957333 0 0 1 95.957334-95.957333z m447.872-106.666667h128c27.648 0 50.346667 20.992 53.077333 47.914667l0.298667 5.418667-0.042667 53.333333h53.333333c41.258667 0 74.666667 33.450667 74.666667 74.666667v192a74.666667 74.666667 0 0 1-74.666667 74.666666h-341.333333a74.666667 74.666667 0 0 1-74.666667-74.666666v-192c0-41.216 33.450667-74.666667 74.666667-74.666667h53.333333v-53.333333c0-27.605333 20.992-50.346667 47.914667-53.034667l5.418667-0.298667h128-128z m117.333333 64h-106.666667v42.666667h106.666667v-42.666667zM426.538667 85.333333a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentPersonAccounts24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentPersonAccounts24Filled;
