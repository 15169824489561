/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSlideSearch24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1194 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M281.30205113 116.4921875A120.84960903 120.84960903 0 0 0 160.45244132 237.34179653v198.06152404a241.52343725 241.52343725 0 0 1 65.91796849-38.75976563V237.34179653c0-30.32226537 24.60937517-54.93164054 54.93164132-54.93164053h637.20703055c30.32226537 0 54.93164054 24.60937517 54.93164132 54.93164053v461.42578176c0 30.32226537-24.60937517 54.93164054-54.93164132 54.93164054H554.86162118l65.91796849 65.91796849h297.72949201A120.84960903 120.84960903 0 0 0 1039.35869149 698.7675783V237.34179653A120.84960903 120.84960903 0 0 0 918.50908168 116.4921875H281.30205113z m171.95800763 307.61718732a243.01757847 243.01757847 0 0 1 63.63281242 65.91796928h313.7255861a32.95898463 32.95898463 0 0 0 0-65.91796928H453.26005876z m289.46777336 197.75390625H555.96025382c0-22.85156233-3.1640625-44.95605443-9.09667969-65.91796849h195.86425799a32.95898463 32.95898463 0 0 1 0 65.91796849zM369.19267553 292.27343785a32.95898463 32.95898463 0 1 0 0 65.91796848h285.64453142a32.95898463 32.95898463 0 0 0 0-65.91796848h-285.64453142zM314.26103498 819.61718732a196.87499982 196.87499982 0 0 0 114.56543021-36.56249957L543.65556624 897.8398434a32.95898463 32.95898463 0 0 0 46.58203107-46.58203107l-114.78515565-114.82910105A197.75390625 197.75390625 0 1 0 314.26103498 819.61718732z m1e-8-65.91796849a131.83593775 131.83593775 0 1 1 0-263.67187473 131.83593775 131.83593775 0 0 1 0 263.67187473z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentSlideSearch24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentSlideSearch24Regular;
