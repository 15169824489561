/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBookExclamationMark24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M565.333333 618.666667a42.666667 42.666667 0 1 1-85.333333 0 42.666667 42.666667 0 0 1 85.333333 0zM490.666667 288v213.333333a32 32 0 0 0 64 0v-213.333333a32 32 0 0 0-64 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M170.666667 192A106.666667 106.666667 0 0 1 277.333333 85.333333H768a106.666667 106.666667 0 0 1 106.666667 106.666667v608a32 32 0 0 1-32 32H234.666667a42.666667 42.666667 0 0 0 42.666666 42.666667h565.333334a32 32 0 0 1 0 64H277.333333A106.666667 106.666667 0 0 1 170.666667 832v-640zM810.666667 768V192a42.666667 42.666667 0 0 0-42.666667-42.666667H277.333333a42.666667 42.666667 0 0 0-42.666666 42.666667V768H810.666667z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentBookExclamationMark24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentBookExclamationMark24Regular;
