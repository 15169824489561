/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentClock24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M650.666667 576h-170.666667a32 32 0 0 1-32-32v-256a32 32 0 0 1 64 0V512h138.666667a32 32 0 0 1 0 64zM512 85.333333C276.394667 85.333333 85.333333 276.394667 85.333333 512s191.061333 426.666667 426.666667 426.666667 426.666667-191.061333 426.666667-426.666667S747.605333 85.333333 512 85.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentClock24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentClock24Filled;
