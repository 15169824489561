/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCube24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M258.090667 340.650667a32 32 0 0 1 41.258666-18.56L512 402.730667l212.650667-80.64a32 32 0 1 1 22.698666 59.818666L544 459.093333v234.282667a32 32 0 0 1-64 0v-234.24L276.650667 381.866667a32 32 0 0 1-18.56-41.258667z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M451.882667 107.178667a160 160 0 0 1 120.234666 0l319.914667 129.706666A74.666667 74.666667 0 0 1 938.666667 306.090667v411.818666a74.666667 74.666667 0 0 1-46.634667 69.205334l-319.914667 129.706666a160 160 0 0 1-120.234666 0l-319.914667-129.706666A74.666667 74.666667 0 0 1 85.333333 717.909333V306.133333a74.666667 74.666667 0 0 1 46.634667-69.205333l319.914667-129.706667z m96.170666 59.306666a96 96 0 0 0-72.106666 0l-319.957334 129.706667a10.666667 10.666667 0 0 0-6.656 9.898667v411.818666a10.666667 10.666667 0 0 0 6.656 9.898667l319.957334 129.706667a96 96 0 0 0 72.106666 0l319.957334-129.706667a10.666667 10.666667 0 0 0 6.656-9.898667V306.133333a10.666667 10.666667 0 0 0-6.656-9.898666l-319.957334-129.706667z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentCube24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentCube24Regular;
