/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shujuzidian: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M779.648 42.666667c30.848 0 60.458667 12.373333 82.261333 34.346666 21.845333 22.016 34.090667 51.882667 34.090667 82.986667v668.8a35.072 35.072 0 0 1-34.901333 35.2H197.802667c0 25.941333 20.864 46.933333 46.549333 46.933333h616.746667c19.285333 0 34.901333 15.786667 34.901333 35.2a35.072 35.072 0 0 1-34.901333 35.2H244.352C180.096 981.333333 128 928.810667 128 864V160C128 95.189333 180.096 42.666667 244.352 42.666667h535.296zM454.826667 304.128l0.085333-0.042667-10.410667 42.709334H394.709333l-4.736 0.341333a35.114667 35.114667 0 0 0-30.165333 34.858667l0.298667 4.778666a34.986667 34.986667 0 0 0 34.56 30.421334h32.768l-18.048 74.453333h-37.077334l-4.736 0.341333a35.114667 35.114667 0 0 0-30.165333 34.901334l0.341333 4.778666a34.986667 34.986667 0 0 0 34.56 30.421334h19.968l-5.717333 23.722666-0.853333 4.693334c-1.792 17.493333 9.472 33.706667 26.453333 37.888l4.693333 0.853333a34.944 34.944 0 0 0 37.546667-26.666667l9.770667-40.533333h71.594666l-5.461333 24.32-0.768 4.693333a35.157333 35.157333 0 0 0 27.008 37.461334l4.693333 0.768a34.944 34.944 0 0 0 37.12-27.221334l9.045334-40.021333h43.776l4.736-0.298667a35.114667 35.114667 0 0 0 30.208-34.858666l-0.341334-4.821334a34.986667 34.986667 0 0 0-34.56-30.378666h-27.776l16.896-74.496h33.493334l4.736-0.341334a35.114667 35.114667 0 0 0 30.165333-34.858666l-0.298667-4.736a34.986667 34.986667 0 0 0-34.56-30.464h-17.536l6.058667-26.453334 0.725333-4.693333a35.157333 35.157333 0 0 0-27.008-37.461333l-4.650666-0.768a34.944 34.944 0 0 0-37.12 27.221333l-9.642667 42.154667h-68.309333l6.314666-25.941334 0.853334-4.693333a34.901333 34.901333 0 0 0-68.693334-12.032z m113.834666 113.066667l-16.853333 74.453333H481.28l18.048-74.453333h69.376z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Shujuzidian.defaultProps = {
  size: 18,
};
*/
export default Shujuzidian;
