/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBranchFork24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M469.333333 234.666667a149.333333 149.333333 0 0 1-128 147.84V490.666667h266.666667a96 96 0 0 0 96-96v-16.853334A149.418667 149.418667 0 0 1 746.666667 85.333333a149.333333 149.333333 0 0 1 21.333333 297.173334v12.16A160 160 0 0 1 608 554.666667H341.333333v86.826666a149.333333 149.333333 0 1 1-64 4.693334v-268.373334A149.418667 149.418667 0 0 1 320 85.333333 149.333333 149.333333 0 0 1 469.333333 234.666667z m-149.333333 85.333333a85.333333 85.333333 0 1 0 0-170.666667 85.333333 85.333333 0 0 0 0 170.666667z m426.666667 0a85.333333 85.333333 0 1 0 0-170.666667 85.333333 85.333333 0 0 0 0 170.666667z m-341.333334 469.333333a85.333333 85.333333 0 1 0-170.666666 0 85.333333 85.333333 0 0 0 170.666666 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentBranchFork24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentBranchFork24Regular;
