/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentBuilding24Regular1: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M362.666667 234.666667a42.666667 42.666667 0 1 0 0 85.333333 42.666667 42.666667 0 0 0 0-85.333333z m-42.666667 341.333333a42.666667 42.666667 0 1 1 85.333333 0 42.666667 42.666667 0 0 1-85.333333 0z m42.666667-192a42.666667 42.666667 0 1 0 0 85.333333 42.666667 42.666667 0 0 0 0-85.333333zM469.333333 277.333333a42.666667 42.666667 0 1 1 85.333334 0 42.666667 42.666667 0 0 1-85.333334 0z m42.666667 256a42.666667 42.666667 0 1 0 0 85.333334 42.666667 42.666667 0 0 0 0-85.333334z m106.666667 42.666667a42.666667 42.666667 0 1 1 85.333333 0 42.666667 42.666667 0 0 1-85.333333 0zM512 384a42.666667 42.666667 0 1 0 0 85.333333 42.666667 42.666667 0 0 0 0-85.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M266.666667 85.333333A96 96 0 0 0 170.666667 181.333333v704c0 17.664 14.336 32 32 32h618.794666a32 32 0 0 0 32-32v-384a96 96 0 0 0-96-96H704V181.333333A96 96 0 0 0 608 85.333333h-341.333333zM234.666667 181.333333a32 32 0 0 1 32-32h341.333333a32 32 0 0 1 32 32v256c0 17.664 14.336 32 32 32h85.461333a32 32 0 0 1 32 32V853.333333H704v-117.333333a32 32 0 0 0-32-32h-320a32 32 0 0 0-32 32V853.333333h-85.333333V181.333333zM640 768v85.333333h-96v-85.333333H640z m-160 0v85.333333H384v-85.333333h96z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
AIcFluentBuilding24Regular1.defaultProps = {
  size: 18,
};
*/
export default AIcFluentBuilding24Regular1;
