/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Xiazai: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M778.688 874.688a32 32 0 0 1 0 64l-554.688 0.128a32 32 0 0 1 0-64l554.688-0.128zM496.96 85.76l4.352-0.256a32 32 0 0 1 31.744 27.648l0.256 4.352v583.872l158.784-158.72a32 32 0 0 1 41.6-3.136l3.648 3.136a32 32 0 0 1 3.072 41.6l-3.072 3.648-213.248 213.184a32 32 0 0 1-41.6 3.136l-3.648-3.136-213.44-213.12a32 32 0 0 1 41.6-48.448l3.584 3.072 158.72 158.464V117.568a32 32 0 0 1 27.648-31.744l4.352-0.256-4.352 0.256z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
Xiazai.defaultProps = {
  size: 18,
};
*/
export default Xiazai;
