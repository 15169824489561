/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPeopleCommunity24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M629.333333 640c41.216 0 74.666667 33.450667 74.666667 74.666667l-0.042667 41.045333c4.992 93.44-64.469333 140.672-189.098666 140.672-124.16 0-194.858667-46.506667-194.858667-139.050667v-42.666666c0-41.216 33.450667-74.666667 74.666667-74.666667h234.666666z m-469.333333-213.333333h186.709333a170.965333 170.965333 0 0 0 45.525334 164.266666l6.912 6.442667L394.666667 597.333333a117.333333 117.333333 0 0 0-113.024 85.674667l-1.450667 0.042667C156.032 683.050667 85.333333 636.544 85.333333 544v-42.666667c0-41.216 33.450667-74.666667 74.666667-74.666666z m704 0c41.216 0 74.666667 33.450667 74.666667 74.666666l-0.042667 41.045334c4.992 93.44-64.469333 140.672-189.098667 140.672l-7.210666-0.085334a117.589333 117.589333 0 0 0-104.576-85.333333L629.333333 597.333333l-4.48 0.042667a170.24 170.24 0 0 0 52.437334-170.666667L864 426.666667zM512 341.333333a128 128 0 1 1 0 256 128 128 0 0 1 0-256zM277.333333 128a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m469.333334 0a128 128 0 1 1 0 256 128 128 0 0 1 0-256z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentPeopleCommunity24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentPeopleCommunity24Filled;
