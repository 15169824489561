/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BookExclamationMark24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 192v640A106.666667 106.666667 0 0 0 277.333333 938.666667h565.333334a32 32 0 0 0 0-64H277.333333a42.666667 42.666667 0 0 1-42.666666-42.666667h608a32 32 0 0 0 32-32V192A106.666667 106.666667 0 0 0 768 85.333333H277.333333A106.666667 106.666667 0 0 0 170.666667 192z m320 309.333333v-213.333333a32 32 0 0 1 64 0v213.333333a32 32 0 0 1-64 0z m74.666666 117.333334a42.666667 42.666667 0 1 1-85.333333 0 42.666667 42.666667 0 0 1 85.333333 0z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
BookExclamationMark24Filled.defaultProps = {
  size: 18,
};
*/
export default BookExclamationMark24Filled;
