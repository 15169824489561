/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Dismiss24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M179.626667 187.178667l3.541333-4.010667a42.666667 42.666667 0 0 1 56.32-3.541333l4.010667 3.541333L512 451.626667l268.501333-268.458667a42.666667 42.666667 0 1 1 60.330667 60.330667L572.373333 512l268.458667 268.501333a42.666667 42.666667 0 0 1 3.541333 56.32l-3.541333 4.010667a42.666667 42.666667 0 0 1-56.32 3.541333l-4.010667-3.541333L512 572.373333l-268.501333 268.458667a42.666667 42.666667 0 0 1-60.330667-60.330667L451.626667 512 183.168 243.498667a42.666667 42.666667 0 0 1-3.541333-56.32l3.541333-4.010667-3.541333 4.010667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Dismiss24Filled.defaultProps = {
  size: 18,
};
*/
export default Dismiss24Filled;
