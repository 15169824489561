/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSlideSearch24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1194 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M160.45244132 237.34179653A120.84960903 120.84960903 0 0 1 281.30205113 116.4921875h637.20703055A120.84960903 120.84960903 0 0 1 1039.35869149 237.34179653v461.42578176A120.84960903 120.84960903 0 0 1 918.50908168 819.61718732h-297.729492l-90.08789046-90.08789045c16.17187492-32.38769523 25.2685546-68.99414097 25.2685546-107.6660158h186.7675783a32.95898463 32.95898463 0 0 0 0-65.91796849H546.86357413a240.5126954 240.5126954 0 0 0-29.97070295-65.91796848h313.7255861a32.95898463 32.95898463 0 0 0 0-65.91796928H453.26005876C413.92900373 396.42382796 365.98466762 380.16406225 314.26103498 380.16406225 255.81376953 380.16406225 202.20048863 400.90625008 160.45244132 435.40332057V237.34179653zM369.19267553 292.27343785a32.95898463 32.95898463 0 1 0 0 65.91796848h285.64453142a32.95898463 32.95898463 0 0 0 0-65.91796848h-285.64453142zM314.26103498 819.61718732a196.87499982 196.87499982 0 0 0 114.56543021-36.56249957L543.65556624 897.8398434a32.95898463 32.95898463 0 0 0 46.58203107-46.58203107l-114.78515565-114.82910105A197.75390625 197.75390625 0 1 0 314.26103498 819.61718732z m1e-8-65.91796849a131.83593775 131.83593775 0 1 1 0-263.67187473 131.83593775 131.83593775 0 0 1 0 263.67187473z"
        fill={getIconColor(color, 0, '#2c2c2c')}
      />
    </svg>
  );
};
/*
IcFluentSlideSearch24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentSlideSearch24Filled;
