/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBuildingRetailToolbox24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M288 341.333333a32 32 0 0 0-32 32v149.333334c0 17.664 14.336 32 32 32h274.688a117.76 117.76 0 0 1 60.373333-64H320v-85.333334h384v74.666667H768v-106.666667a32 32 0 0 0-32-32H288z m202.666667 288V640c-13.397333 17.834667-21.333333 39.978667-21.333334 64V896H160a32 32 0 0 1-32-32V287.232A85.333333 85.333333 0 0 1 170.666667 128h682.666666a85.333333 85.333333 0 0 1 42.666667 159.232v219.605333a116.778667 116.778667 0 0 0-64-26.368V298.666667h-640v533.333333H256v-202.666667a32 32 0 0 1 32-32h170.666667a32 32 0 0 1 32 32zM149.333333 213.333333a21.333333 21.333333 0 0 0 21.333334 21.333334h682.666666a21.333333 21.333333 0 0 0 0-42.666667H170.666667a21.333333 21.333333 0 0 0-21.333334 21.333333zM426.666667 832v-170.666667H320v170.666667H426.666667z m170.666666-192h-21.333333a64 64 0 0 0-64 64V768h106.666667v-10.666667a32 32 0 0 1 64 0V768h128v-10.666667a32 32 0 0 1 64 0V768H981.333333v-64a64 64 0 0 0-64-64H896v-42.666667a74.666667 74.666667 0 0 0-74.666667-74.666666h-149.333333A74.666667 74.666667 0 0 0 597.333333 597.333333v42.666667z m64-42.666667a10.666667 10.666667 0 0 1 10.666667-10.666666h149.333333a10.666667 10.666667 0 0 1 10.666667 10.666666v42.666667h-170.666667v-42.666667zM512 917.333333v-85.333333h106.666667v32a32 32 0 0 0 64 0v-32h128v32a32 32 0 0 0 64 0v-32H981.333333v85.333333a64 64 0 0 1-64 64h-341.333333a64 64 0 0 1-64-64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentBuildingRetailToolbox24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentBuildingRetailToolbox24Regular;
