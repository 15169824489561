/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSelectAllOn24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M874.496 240.085333A96 96 0 0 1 938.666667 330.666667v426.666666A181.333333 181.333333 0 0 1 757.333333 938.666667h-426.666666a96 96 0 0 1-90.581334-64.170667l89.472 0.170667H757.333333a117.333333 117.333333 0 0 0 117.333334-117.333334v-426.666666l-0.170667-2.176V240.085333zM735.829333 85.333333a96 96 0 0 1 96 96v554.538667a96 96 0 0 1-96 96H181.333333A96 96 0 0 1 85.333333 735.872V181.333333A96 96 0 0 1 181.333333 85.333333h554.538667zM574.72 318.72l-165.973333 165.888-41.813334-55.808a32 32 0 1 0-51.2 38.4l64 85.333333a32 32 0 0 0 48.213334 3.413334l192-192a32 32 0 0 0-45.226667-45.226667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentSelectAllOn24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentSelectAllOn24Filled;
