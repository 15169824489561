/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentClipboardTaskListRtl24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M586.666667 85.333333h-149.333334a96 96 0 0 0-95.402666 85.333334H266.666667A96 96 0 0 0 170.666667 266.666667v576A96 96 0 0 0 266.666667 938.666667h490.666666A96 96 0 0 0 853.333333 842.666667V266.666667A96 96 0 0 0 757.333333 170.666667h-75.264a96 96 0 0 0-95.402666-85.333334z m-149.333334 64h149.333334a32 32 0 0 1 0 64h-149.333334a32 32 0 0 1 0-64z m299.946667 222.72a32 32 0 0 1 0 45.226667l-85.333333 85.333333a32 32 0 0 1-45.226667 0l-42.666667-42.666666a32 32 0 1 1 45.226667-45.226667l20.053333 20.053333 62.72-62.72a32 32 0 0 1 45.226667 0z m0 279.893334l-85.333333 85.333333a32 32 0 0 1-45.226667 0l-42.666667-42.666667a32 32 0 1 1 45.226667-45.226666l20.053333 20.053333 62.72-62.72a32 32 0 1 1 45.226667 45.226667zM298.666667 437.333333a32 32 0 0 1 32-32h149.333333a32 32 0 0 1 0 64h-149.333333a32 32 0 0 1-32-32zM330.666667 640h149.333333a32 32 0 0 1 0 64h-149.333333a32 32 0 0 1 0-64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentClipboardTaskListRtl24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentClipboardTaskListRtl24Filled;
