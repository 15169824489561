/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentBookGlobe24Filled1: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 192A106.666667 106.666667 0 0 1 277.333333 85.333333H768a106.666667 106.666667 0 0 1 106.666667 106.666667v608a32 32 0 0 1-32 32H234.666667a42.666667 42.666667 0 0 0 42.666666 42.666667h565.333334a32 32 0 0 1 0 64H277.333333A106.666667 106.666667 0 0 1 170.666667 832v-640z m279.509333 79.701333a202.837333 202.837333 0 0 0-123.733333 165.632h89.898666c1.749333-53.333333 10.069333-102.272 23.338667-140.074666 3.114667-8.917333 6.613333-17.493333 10.496-25.6z m142.506667-4.949333c4.906667 9.472 9.216 19.754667 12.970666 30.506667 13.226667 37.802667 21.546667 86.741333 23.338667 140.074666h100.565333a202.837333 202.837333 0 0 0-136.874666-170.581333z m-27.733334 170.581333c-1.706667-47.445333-9.216-88.96-19.669333-118.954666-6.101333-17.322667-12.586667-29.098667-18.218667-35.84a28.928 28.928 0 0 0-4.394666-4.522667 28.928 28.928 0 0 0-4.394667 4.48c-5.632 6.826667-12.117333 18.56-18.218667 35.882667-10.453333 29.994667-17.92 71.509333-19.626666 118.954666h84.48z m-83.370666 64c2.901333 38.528 9.6 72.234667 18.474666 97.621334 6.101333 17.322667 12.586667 29.098667 18.218667 35.84a27.733333 27.733333 0 0 0 4.394667 4.522666 28.885333 28.885333 0 0 0 4.394666-4.48c5.632-6.826667 12.117333-18.56 18.218667-35.882666 8.874667-25.386667 15.573333-59.093333 18.474667-97.621334h-82.176z m-64.170667 0H329.813333a203.050667 203.050667 0 0 0 120.661334 144.853334 241.322667 241.322667 0 0 1-10.752-26.112c-11.477333-32.725333-19.2-73.728-22.272-118.741334z m174.976 149.76a203.008 203.008 0 0 0 133.845333-149.76h-98.304c-3.029333 45.013333-10.794667 86.016-22.272 118.741334-3.84 10.965333-8.234667 21.418667-13.226666 31.018666z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
AIcFluentBookGlobe24Filled1.defaultProps = {
  size: 18,
};
*/
export default AIcFluentBookGlobe24Filled1;
