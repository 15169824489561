/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Fanyi01: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M810.666667 298.666667h-273.493334l-15.36-81.066667H247.466667A72.533333 72.533333 0 0 0 170.666667 284.586667v341.333333a72.96 72.96 0 0 0 76.8 65.28h217.6v62.72a48.64 48.64 0 0 0 42.666666 52.48h298.666667a48.64 48.64 0 0 0 42.666667-52.48V352.426667A49.066667 49.066667 0 0 0 810.666667 298.666667zM375.466667 567.466667H223.573333V345.173333h147.2v32.853334H256v58.88h108.373333V469.333333H256v67.413334h119.466667z m93.866666-136.96a36.693333 36.693333 0 0 0-36.693333 37.12v99.84h-29.866667V403.626667h29.866667v20.053333a42.666667 42.666667 0 0 1 42.666667-23.466667c35.84 0 53.76 20.053333 52.48 63.146667v104.106667h-30.72v-100.693334c-2.986667-24.32-11.946667-36.266667-27.733334-36.266666z m341.333334 323.413333v9.386667h-298.666667a14.933333 14.933333 0 0 1-2.986667-9.813334v-62.72h102.826667L545.28 341.333333h261.546667a12.8 12.8 0 0 1 3.413333 9.813334z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M682.666667 594.346667a200.106667 200.106667 0 0 0 42.666666-97.706667h42.666667v-30.72h-85.333333v-4.693333a259.413333 259.413333 0 0 0-18.346667-32.853334l-2.986667-5.12-27.306666 14.506667 2.56 5.973333c5.546667 10.24 8.96 17.066667 11.946666 22.186667h-49.066666l20.053333 106.666667a250.026667 250.026667 0 0 0 15.786667 21.333333l-10.666667 6.826667 5.973333 32 27.733334-17.066667a358.4 358.4 0 0 0 93.866666 42.666667h5.546667l2.56-4.693334 5.973333-10.24c0-4.266667 3.413333-7.68 4.266667-10.24l3.413333-7.68h-7.68a250.88 250.88 0 0 1-83.626666-31.146666z m-61.44-97.706667h74.666666a157.013333 157.013333 0 0 1-35.84 76.373333 170.666667 170.666667 0 0 1-39.68-76.373333z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
Fanyi01.defaultProps = {
  size: 18,
};
*/
export default Fanyi01;
