/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const People24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M149.333333 597.333333l426.666667 0.042667a64 64 0 0 1 63.701333 57.856L640 661.333333v85.333334C639.957333 896 481.450667 938.666667 362.666667 938.666667c-116.138667 0-270.293333-40.789333-277.12-182.186667L85.333333 746.666667v-85.333334a64 64 0 0 1 57.856-63.701333L149.333333 597.333333z m511.488 0H874.666667a64 64 0 0 1 63.701333 57.813334L938.666667 661.333333V725.333333c-0.042667 130.645333-121.941333 170.666667-213.333334 170.666667a305.493333 305.493333 0 0 1-91.306666-13.738667c27.861333-32 45.909333-72.661333 48.341333-123.648L682.666667 746.666667v-85.333334c0-21.077333-6.4-40.576-17.024-57.088L660.821333 597.333333H874.666667h-213.845334zM362.666667 128a192 192 0 1 1 0 384 192 192 0 0 1 0-384z m384 85.333333a149.333333 149.333333 0 1 1 0 298.666667 149.333333 149.333333 0 0 1 0-298.666667z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
People24Filled.defaultProps = {
  size: 18,
};
*/
export default People24Filled;
