/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const DesktopPulseFilled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M256 906.666667c0 17.664 14.336 32 32 32h448l4.352-0.298667a32 32 0 0 0-4.352-63.701333h-74.709333v-106.581334h181.333333l6.570667-0.213333a96 96 0 0 0 89.429333-95.786667V554.666667H725.333333a74.666667 74.666667 0 0 1-61.184-31.829334l-8.192-11.690666-79.658666 134.826666a74.666667 74.666667 0 0 1-133.632-10.24l-62.634667-156.586666-14.08 31.445333A74.666667 74.666667 0 0 1 297.813333 554.666667H85.333333v117.418666l0.213334 6.570667a96 96 0 0 0 95.786666 89.429333h181.290667V874.666667H288l-4.352 0.298666A32 32 0 0 0 256 906.666667z m170.581333-138.581334h170.666667l0.042667 106.581334h-170.666667l-0.042667-106.581334z m512-544.085333V405.333333h-174.378666l-52.352-74.794666a74.666667 74.666667 0 0 0-125.44 4.821333l-61.44 103.978667-71.68-179.072a74.666667 74.666667 0 0 0-137.386667-2.816L249.472 405.333333H85.333333V224a96 96 0 0 1 89.429334-95.786667L181.333333 128h661.290667a96 96 0 0 1 95.786667 89.429333l0.213333 6.570667zM413.738667 276.138667a32 32 0 0 0-58.88-1.237334L277.077333 448H117.333333a32 32 0 0 0 0 64h180.48a32 32 0 0 0 29.184-18.901333L382.293333 369.92l100.010667 249.984a32 32 0 0 0 57.258667 4.394667l113.365333-191.914667 46.208 65.962667c5.973333 8.576 15.786667 13.653333 26.197333 13.653333h181.333334a32 32 0 0 0 0-64h-164.693334l-65.109333-93.013333a32 32 0 0 0-53.76 2.090666l-105.557333 178.645334-103.850667-259.584z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
DesktopPulseFilled.defaultProps = {
  size: 18,
};
*/
export default DesktopPulseFilled;
