/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Renyuanguanli: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M865.536 232.106667A158.506667 158.506667 0 0 1 938.666667 365.696V682.666667a256 256 0 0 1-256 256H365.696a158.378667 158.378667 0 0 1-133.589333-73.130667H682.666667A182.869333 182.869333 0 0 0 865.536 682.666667zM658.304 85.333333a158.464 158.464 0 0 1 158.464 158.464v414.506667a158.464 158.464 0 0 1-158.464 158.464H243.797333A158.464 158.464 0 0 1 85.333333 658.304V243.797333A158.464 158.464 0 0 1 243.797333 85.333333zM597.333333 463.232H304.768c-40.405333 0-73.130667 32.768-73.130667 73.130667v24.405333c0 24.192 5.674667 41.941333 15.701334 56.576 10.112 14.933333 25.301333 27.477333 45.738666 40.448 38.741333 24.533333 93.610667 37.077333 157.952 37.077333 76.586667 0 131.413333-12.544 167.168-37.077333 18.944-13.056 31.701333-25.685333 39.893334-40.448 8.192-14.634667 12.373333-32.426667 12.373333-56.576v-24.405333c0-40.362667-32.725333-73.130667-73.130667-73.130667z m-146.304-268.202667a109.696 109.696 0 1 0 0 219.434667 109.696 109.696 0 0 0 0-219.434667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Renyuanguanli.defaultProps = {
  size: 18,
};
*/
export default Renyuanguanli;
