/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentArrowReply24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M395.818667 692.096a32 32 0 0 1-45.269334 45.226667l-213.205333-213.461334a32 32 0 0 1 0-45.226666L350.592 265.386667a32 32 0 0 1 45.269333 45.226666L237.098667 469.333333h334.592c68.053333 0 119.893333 10.325333 165.930666 32.597334l10.496 5.376a264.661333 264.661333 0 0 1 109.909334 109.909333c26.026667 48.64 37.973333 103.168 37.973333 176.426667a32 32 0 0 1-64 0c0-63.317333-9.728-107.52-30.421333-146.261334a200.618667 200.618667 0 0 0-83.626667-83.626666c-35.754667-19.114667-76.202667-28.842667-132.010667-30.250667L571.733333 533.333333H237.312l158.506667 158.762667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentArrowReply24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentArrowReply24Regular;
