/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Desktop24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 373.76a48.64 48.64 0 1 0 48.64 48.64A48.64 48.64 0 0 0 512 373.76z"
        fill={getIconColor(color, 0, '#212121')}
      />
      <path
        d="M938.666667 196.266667a96 96 0 0 0-95.573334-89.6H174.933333A96 96 0 0 0 85.333333 202.666667v454.826666a95.573333 95.573333 0 0 0 96 89.173334H362.666667V853.333333H283.733333a32 32 0 0 0 4.266667 63.573334h452.266667a32 32 0 0 0 28.16-32 32.426667 32.426667 0 0 0-32.426667-31.573334H661.333333v-106.666666h187.733334a96.426667 96.426667 0 0 0 89.6-96V196.266667zM426.666667 853.333333v-106.666666h170.666666V853.333333z m238.933333-287.573333a16.213333 16.213333 0 0 1-18.346667 3.84l-30.293333-14.933333a29.866667 29.866667 0 0 0-39.253333 14.933333 23.04 23.04 0 0 0 0 8.96l-3.84 32.853333a16.213333 16.213333 0 0 1-12.373334 14.08 209.066667 209.066667 0 0 1-94.72 0 16.213333 16.213333 0 0 1-12.373333-14.08l-3.84-32.853333a29.866667 29.866667 0 0 0-34.986667-23.893333 28.16 28.16 0 0 0-8.533333 2.56l-30.293333 13.226666a16.213333 16.213333 0 0 1-18.346667-3.84 210.773333 210.773333 0 0 1-47.36-81.92 15.36 15.36 0 0 1 5.546667-17.493333l26.88-20.053333a29.44 29.44 0 0 0 6.4-42.666667 23.04 23.04 0 0 0-6.4-6.4l-26.88-19.626667a16.213333 16.213333 0 0 1-5.546667-17.92A210.773333 210.773333 0 0 1 358.4 279.04a16.213333 16.213333 0 0 1 18.346667-3.413333l30.293333 13.226666a29.866667 29.866667 0 0 0 39.253333-15.786666 19.626667 19.626667 0 0 0 0-8.533334l3.84-32.853333a16.213333 16.213333 0 0 1 12.373334-14.08A256 256 0 0 1 512 213.333333a247.466667 247.466667 0 0 1 46.933333 5.546667 16.213333 16.213333 0 0 1 12.373334 14.08l3.84 32.853333a29.866667 29.866667 0 0 0 32.853333 26.453334l8.533333-2.133334 30.293334-13.226666a16.213333 16.213333 0 0 1 18.346666 3.413333 210.773333 210.773333 0 0 1 47.36 81.92 17.066667 17.066667 0 0 1-5.546666 17.92l-24.32 18.346667a29.44 29.44 0 0 0-6.4 42.666666 23.04 23.04 0 0 0 6.4 6.4l26.88 20.053334a15.36 15.36 0 0 1 5.546666 17.493333 210.773333 210.773333 0 0 1-49.493333 80.64z"
        fill={getIconColor(color, 1, '#212121')}
      />
      <path
        d="M648.106667 384a64.853333 64.853333 0 0 1 13.226666-13.226667l17.066667-12.8a177.493333 177.493333 0 0 0-29.013333-49.92l-19.2 8.533334a62.293333 62.293333 0 0 1-81.92-32 70.826667 70.826667 0 0 1-5.12-17.92l-2.133334-21.333334c-9.386667 0-19.2 0-28.586666-2.56q-14.933333 0-29.44 2.56l-2.133334 21.333334a61.866667 61.866667 0 0 1-68.693333 56.32 55.466667 55.466667 0 0 1-18.346667-4.693334l-19.2-8.533333a177.493333 177.493333 0 0 0-29.013333 49.92l17.066667 12.8a61.44 61.44 0 0 1 13.226666 85.333333 48.64 48.64 0 0 1-13.226666 13.226667l-17.066667 12.8a170.666667 170.666667 0 0 0 29.013333 49.92l19.626667-8.533333a63.146667 63.146667 0 0 1 85.333333 50.346666l2.133334 20.906667a165.546667 165.546667 0 0 0 58.026666 0l2.133334-20.906667a62.293333 62.293333 0 0 1 68.693333-55.04 61.44 61.44 0 0 1 17.92 4.693334l19.626667 8.533333a170.666667 170.666667 0 0 0 29.013333-49.92l-17.066667-12.8A61.44 61.44 0 0 1 648.106667 384zM512 503.466667a81.066667 81.066667 0 1 1 80.64-81.066667A81.066667 81.066667 0 0 1 512 503.466667z"
        fill={getIconColor(color, 2, '#212121')}
      />
    </svg>
  );
};
/*
Desktop24Filled.defaultProps = {
  size: 18,
};
*/
export default Desktop24Filled;
