/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Jincoude: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M964.923077 856.615385a39.384615 39.384615 0 0 1 39.384615 39.384615v39.384615a39.384615 39.384615 0 0 1-39.384615 39.384616h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384616v-39.384615a39.384615 39.384615 0 0 1 39.384615-39.384615h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384615v39.384615a39.384615 39.384615 0 0 1-39.384616 39.384616h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384616v-39.384615a39.384615 39.384615 0 0 1 39.384615-39.384615h39.384615z m866.461539-275.692308a39.384615 39.384615 0 0 1 39.384615 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384615 39.384615h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384616 39.384615h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h39.384615z m866.461539-275.692308a39.384615 39.384615 0 0 1 39.384615 39.384616v39.384615a39.384615 39.384615 0 0 1-39.384615 39.384615h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384615a39.384615 39.384615 0 0 1 39.384615-39.384616h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384616v39.384615a39.384615 39.384615 0 0 1-39.384616 39.384615h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384615a39.384615 39.384615 0 0 1 39.384615-39.384616h39.384615z m866.461539-256a39.384615 39.384615 0 0 1 39.384615 39.384616v39.384615a39.384615 39.384615 0 0 1-39.384615 39.384615h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384615a39.384615 39.384615 0 0 1 39.384615-39.384616h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384616v39.384615a39.384615 39.384615 0 0 1-39.384616 39.384615h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384615a39.384615 39.384615 0 0 1 39.384615-39.384616h39.384615z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Jincoude.defaultProps = {
  size: 18,
};
*/
export default Jincoude;
