/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCalendarClock24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M704 469.333333a234.666667 234.666667 0 1 1 0 469.333334 234.666667 234.666667 0 0 1 0-469.333334zM853.333333 320v150.272A277.333333 277.333333 0 0 0 470.272 853.333333H224A138.666667 138.666667 0 0 1 85.333333 714.666667V320h768z m-170.666666 256a21.333333 21.333333 0 0 0-21.333334 21.333333v128a21.333333 21.333333 0 0 0 21.333334 21.333334h106.666666a21.333333 21.333333 0 1 0 0-42.666667h-85.333333V597.333333a21.333333 21.333333 0 0 0-21.333333-21.333333zM714.666667 85.333333A138.666667 138.666667 0 0 1 853.333333 224V256H85.333333v-32A138.666667 138.666667 0 0 1 224 85.333333h490.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentCalendarClock24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentCalendarClock24Filled;
