/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentPositionToFront24Regular1: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M42.666667 160A117.333333 117.333333 0 0 1 160 42.666667h149.333333A117.333333 117.333333 0 0 1 426.666667 160V213.333333h-32c-10.922667 0-21.632 0.981333-32 2.816V160c0-29.44-23.893333-53.333333-53.333334-53.333333h-149.333333c-29.44 0-53.333333 23.893333-53.333333 53.333333v149.333333c0 29.44 23.893333 53.333333 53.333333 53.333334h56.149333A182.442667 182.442667 0 0 0 213.333333 394.666667V426.666667H160A117.333333 117.333333 0 0 1 42.666667 309.333333v-149.333333zM597.333333 810.666667v53.333333A117.333333 117.333333 0 0 0 714.666667 981.333333h149.333333A117.333333 117.333333 0 0 0 981.333333 864v-149.333333A117.333333 117.333333 0 0 0 864 597.333333H810.666667v32c0 10.922667-0.981333 21.632-2.816 32h56.149333c29.44 0 53.333333 23.893333 53.333333 53.333334v149.333333c0 29.44-23.893333 53.333333-53.333333 53.333333h-149.333333c-29.44 0-53.333333-23.893333-53.333334-53.333333v-56.149333a182.229333 182.229333 0 0 1-32 2.816H597.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M394.666667 256A138.666667 138.666667 0 0 0 256 394.666667v234.666666A138.666667 138.666667 0 0 0 394.666667 768h234.666666A138.666667 138.666667 0 0 0 768 629.333333v-234.666666A138.666667 138.666667 0 0 0 629.333333 256h-234.666666z m98.688 64L320 493.354667v-80.213334L413.184 320h80.170667zM320 583.850667L583.850667 320h45.482666c10.24 0 20.010667 2.048 28.885334 5.802667l-332.416 332.373333a74.453333 74.453333 0 0 1-5.802667-28.842667v-45.482666z m53.546667 117.12l327.424-327.424c1.962667 6.698667 3.029333 13.781333 3.029333 21.12v56.021333L450.645333 704H394.666667c-7.338667 0-14.421333-1.066667-21.12-3.029333z m253.098666 3.029333h-85.461333l162.816-162.816v88.149333l-1.408-1.408-75.989333 75.989334 0.085333 0.085333z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
AIcFluentPositionToFront24Regular1.defaultProps = {
  size: 18,
};
*/
export default AIcFluentPositionToFront24Regular1;
