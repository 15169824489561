/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const FapiaoguanliXianxing: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M687.488 85.333333c54.528 0 98.773333 42.965333 98.773333 96v661.333334c0 53.034667-44.245333 96-98.773333 96H226.730667C172.202667 938.666667 128 895.701333 128 842.666667V181.333333C128 128.298667 172.202667 85.333333 226.730667 85.333333h460.8z m0 64H226.730667a32.426667 32.426667 0 0 0-32.896 32v661.333334c0 17.664 14.72 32 32.896 32h460.8a32.426667 32.426667 0 0 0 32.853333-32V181.333333a32.426667 32.426667 0 0 0-32.853333-32z m142.677333 490.752h32.938667c16.426667 0 30.336 11.818667 32.597333 27.648l0.298667 4.352v63.914667a32.298667 32.298667 0 0 1-28.458667 31.701333L863.146667 768h-32.938667v-127.914667h32.938667-32.938667z m0-170.666666h32.938667c16.426667 0 30.336 11.818667 32.597333 27.648l0.298667 4.352v63.914666a32.298667 32.298667 0 0 1-28.458667 31.701334L863.146667 597.333333h-32.938667v-127.914666h32.938667-32.938667z m0-170.666667h32.938667c16.426667 0 30.336 11.818667 32.597333 27.648l0.298667 4.352V394.666667a32.298667 32.298667 0 0 1-28.458667 31.701333L863.146667 426.666667h-32.938667V298.752h32.938667-32.938667zM621.653333 213.333333a32.426667 32.426667 0 0 1 32.938667 32v106.581334a32.426667 32.426667 0 0 1-32.938667 32H292.565333a32.426667 32.426667 0 0 1-32.896-32V245.333333A32.426667 32.426667 0 0 1 292.565333 213.333333h329.088zM588.8 277.333333h-263.253333v42.581334h263.253333V277.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
FapiaoguanliXianxing.defaultProps = {
  size: 18,
};
*/
export default FapiaoguanliXianxing;
