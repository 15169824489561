/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDismissCircle24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m0 64a362.666667 362.666667 0 1 0 0 725.333334 362.666667 362.666667 0 0 0 0-725.333334z m147.029333 208.938667l3.584 3.114667a32 32 0 0 1 3.114667 41.642666l-3.114667 3.584L557.269333 512l105.386667 105.386667a32 32 0 0 1 3.072 41.642666l-3.114667 3.584a32 32 0 0 1-41.642666 3.114667l-3.584-3.114667L512 557.269333l-105.386667 105.386667a32 32 0 0 1-41.642666 3.072l-3.584-3.114667a32 32 0 0 1-3.114667-41.642666l3.114667-3.584L466.730667 512l-105.386667-105.386667a32 32 0 0 1-3.072-41.642666l3.114667-3.584a32 32 0 0 1 41.642666-3.114667l3.584 3.114667L512 466.730667l105.386667-105.386667a32 32 0 0 1 41.642666-3.072z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentDismissCircle24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentDismissCircle24Regular;
