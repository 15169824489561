/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Apps24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M788.992 99.413333l135.637333 135.637334a96 96 0 0 1 0 135.765333l-110.250666 110.250667A96 96 0 0 1 896 576v224A96 96 0 0 1 800 896H224A96 96 0 0 1 128 800V224A96 96 0 0 1 224 128h224a96 96 0 0 1 94.933333 81.706667L653.226667 99.413333a96 96 0 0 1 135.765333 0zM192 800c0 17.664 14.336 32 32 32l255.957333-0.042667 0.042667-288H192v256z m351.957333 31.957333h256.042667a32 32 0 0 0 32-32V576a32 32 0 0 0-32-32h-256.042667v288z m-95.957333-640H224a32 32 0 0 0-32 32v256h288v-256a32 32 0 0 0-32-32z m96 205.226667v82.773333h82.773333l-82.773333-82.773333z m154.453333-252.501333l-135.594666 135.594666a32 32 0 0 0 0 45.269334l135.637333 135.637333a32 32 0 0 0 45.226667 0l135.68-135.637333a32 32 0 0 0 0-45.226667l-135.68-135.68a32 32 0 0 0-45.226667 0z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
Apps24Regular.defaultProps = {
  size: 18,
};
*/
export default Apps24Regular;
