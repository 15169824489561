/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentStarSettings24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M460.288 132.394667c21.12-42.837333 82.176-42.837333 103.296 0l100.608 203.818666 224.981333 32.682667c47.232 6.869333 66.090667 64.938667 31.914667 98.261333l-38.613333 37.632a275.712 275.712 0 0 0-64.725334-26.282666l49.365334-48.128-215.466667-31.317334a57.6 57.6 0 0 1-43.349333-31.488l-96.384-195.242666L415.573333 367.573333a57.6 57.6 0 0 1-43.349333 31.488l-215.466667 31.317334 155.904 151.978666c13.568 13.226667 19.754667 32.298667 16.554667 50.986667l-36.778667 214.613333 177.024-93.098666a277.76 277.76 0 0 0 10.154667 66.986666l-168.874667 88.746667c-42.24 22.229333-91.648-13.653333-83.626666-60.714667l38.442666-224-162.773333-158.72c-34.176-33.28-15.317333-91.392 31.914667-98.261333L359.68 336.213333l100.608-203.818666z m148.906667 463.872a85.333333 85.333333 0 0 1-61.482667 106.538666l-24.917333 6.144a244.437333 244.437333 0 0 0 0.256 77.098667l23.04 5.546667a85.333333 85.333333 0 0 1 61.866666 107.093333l-7.978666 26.965333c18.773333 16.469333 40.106667 29.824 63.317333 39.296l21.077333-22.101333a85.333333 85.333333 0 0 1 123.690667 0l21.248 22.4a225.322667 225.322667 0 0 0 63.274667-38.954667l-8.448-29.269333a85.333333 85.333333 0 0 1 61.482666-106.496l24.917334-6.144a243.882667 243.882667 0 0 0-0.256-77.141333l-23.04-5.546667a85.333333 85.333333 0 0 1-61.866667-107.093333l7.978667-26.88a225.194667 225.194667 0 0 0-63.317334-39.381334l-21.034666 22.144a85.333333 85.333333 0 0 1-123.733334 0l-21.248-22.4c-23.210667 9.386667-44.544 22.613333-63.274666 38.912l8.448 29.269334zM746.666667 810.666667c-34.133333 0-61.866667-28.672-61.866667-64 0-35.370667 27.733333-64 61.866667-64 34.133333 0 61.866667 28.629333 61.866666 64 0 35.328-27.733333 64-61.866666 64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentStarSettings24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentStarSettings24Regular;
