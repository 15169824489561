/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentCall24Regular2: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M301.056 103.168l49.792-14.976a117.333333 117.333333 0 0 1 140.885333 64.213333l38.485334 85.589334a117.333333 117.333333 0 0 1-27.008 133.930666L439.466667 431.36a10.666667 10.666667 0 0 0-3.328 6.613333c-1.877333 16.938667 9.6 49.92 36.053333 95.786667 19.242667 33.322667 36.693333 56.746667 51.498667 69.845333 10.325333 9.173333 16 11.136 18.432 10.453334l85.76-26.24a117.333333 117.333333 0 0 1 129.450666 43.52l54.656 75.776a117.333333 117.333333 0 0 1-14.464 153.813333l-37.802666 35.84a160 160 0 0 1-153.045334 37.930667c-117.504-32.810667-222.848-131.968-317.226666-295.424-94.506667-163.669333-127.658667-304.64-97.109334-422.954667a160 160 0 0 1 108.714667-113.152z m18.474667 61.312a96 96 0 0 0-65.237334 67.84c-25.685333 99.498667 3.712 224.469333 90.581334 374.954667 86.741333 150.272 180.138667 238.165333 279.04 265.813333a96 96 0 0 0 91.776-22.784l37.845333-35.84a53.333333 53.333333 0 0 0 6.570667-69.930667l-54.613334-75.733333a53.333333 53.333333 0 0 0-58.88-19.797333l-85.973333 26.325333c-49.92 14.848-95.232-25.301333-143.872-109.568C384 509.013333 368.725333 465.066667 372.522667 430.890667c2.005333-17.749333 10.24-34.133333 23.296-46.336l63.744-59.434667a53.333333 53.333333 0 0 0 12.288-60.885333l-38.485334-85.589334a53.333333 53.333333 0 0 0-64-29.184l-49.834666 15.018667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
AIcFluentCall24Regular2.defaultProps = {
  size: 18,
};
*/
export default AIcFluentCall24Regular2;
