/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shoukuanguanli: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M149.333333 192C149.333333 133.12 198.528 85.333333 259.242667 85.333333h505.514666c29.141333 0 57.130667 11.221333 77.738667 31.232 20.608 20.053333 32.170667 47.146667 32.170667 75.434667v608c0 17.664-14.762667 32-32.981334 32H215.253333c0 23.552 19.712 42.666667 43.946667 42.666667h582.485333c18.218667 0 32.981333 14.336 32.981334 32s-14.762667 32-32.981334 32H259.242667C198.528 938.666667 149.333333 890.88 149.333333 832v-640z m272.426667 126.464C396.458667 330.197333 369.152 351.573333 369.152 384s27.306667 53.76 52.608 65.536c27.306667 12.672 63.146667 19.797333 101.248 19.797333 38.058667 0 73.898667-7.125333 101.205333-19.797333 25.301333-11.733333 52.650667-33.109333 52.650667-65.536s-27.306667-53.76-52.650667-65.536C596.821333 305.792 561.066667 298.666667 523.008 298.666667c-38.101333 0-73.898667 7.125333-101.248 19.797333z m254.848 146.176c-36.266667 28.885333-91.648 47.36-153.6 47.36-61.994667 0-117.376-18.474667-153.642667-47.36a49.194667 49.194667 0 0 0-0.213333 4.693333c0 47.146667 68.864 85.333333 153.856 85.333334 84.949333 0 153.856-38.186667 153.856-85.333334a55.893333 55.893333 0 0 0-0.256-4.693333z m-153.6 132.693333c-61.994667 0-117.376-18.474667-153.642667-47.36a49.237333 49.237333 0 0 0-0.213333 4.693334c0 47.146667 68.864 85.333333 153.856 85.333333 84.949333 0 153.856-38.186667 153.856-85.333333 0-1.578667-0.085333-3.114667-0.256-4.693334-36.266667 28.885333-91.648 47.36-153.6 47.36z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
Shoukuanguanli.defaultProps = {
  size: 18,
};
*/
export default Shoukuanguanli;
