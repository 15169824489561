/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCopy24Regular101011: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M256 917.333333a78.933333 78.933333 0 0 1-76.8-79.786666V375.466667A78.933333 78.933333 0 0 1 256 295.68h315.306667a78.933333 78.933333 0 0 1 80.213333 79.786667v462.08a78.933333 78.933333 0 0 1-78.506667 79.786666z m0-53.333333h315.306667a26.026667 26.026667 0 0 0 26.026666-26.453333V375.466667a26.026667 26.026667 0 0 0-26.026666-26.453334H256a26.026667 26.026667 0 0 0-26.026667 26.453334v462.08a26.026667 26.026667 0 0 0 26.026667 26.453333z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M700.16 829.44V389.12a152.32 152.32 0 0 0-26.453333-112.213333A163.84 163.84 0 0 0 554.666667 244.053333H285.866667a81.92 81.92 0 0 1 77.226666-51.2h213.333334a180.906667 180.906667 0 0 1 136.106666 42.666667c28.16 29.013333 39.68 77.226667 37.12 156.16v363.093333a89.173333 89.173333 0 0 1-48.213333 79.36z m94.72-98.986667V290.133333a145.493333 145.493333 0 0 0-145.066667-145.493333h-268.8a82.346667 82.346667 0 0 1 77.226667-50.773333h192a196.693333 196.693333 0 0 1 196.266667 196.266666v362.666667a89.173333 89.173333 0 0 1-48.213334 79.36z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentCopy24Regular101011.defaultProps = {
  size: 18,
};
*/
export default IcFluentCopy24Regular101011;
