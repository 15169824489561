/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const DocumentLandscapeData24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M85.333333 288A117.333333 117.333333 0 0 1 202.666667 170.666667h618.666666A117.333333 117.333333 0 0 1 938.666667 288v448A117.333333 117.333333 0 0 1 821.333333 853.333333H202.666667A117.333333 117.333333 0 0 1 85.333333 736V288zM512 298.666667a85.333333 85.333333 0 0 0-85.333333 85.333333v256a85.333333 85.333333 0 1 0 170.666666 0V384a85.333333 85.333333 0 0 0-85.333333-85.333333z m-213.333333 213.333333a85.333333 85.333333 0 0 0-85.333334 85.333333v42.666667a85.333333 85.333333 0 1 0 170.666667 0v-42.666667a85.333333 85.333333 0 0 0-85.333333-85.333333z m341.333333 0v128a85.333333 85.333333 0 1 0 170.666667 0v-128a85.333333 85.333333 0 1 0-170.666667 0z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
DocumentLandscapeData24Filled.defaultProps = {
  size: 18,
};
*/
export default DocumentLandscapeData24Filled;
