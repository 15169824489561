/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A20Regular1: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M313.856 614.4A271.36 271.36 0 0 0 512 336.896V204.8a20.48 20.48 0 0 0-19.968-16.896 204.8 204.8 0 0 1-30.72 0 199.68 199.68 0 0 1-51.2-13.824 262.656 262.656 0 0 1-88.576-65.536 19.968 19.968 0 0 0-28.672 0A229.888 229.888 0 0 1 122.88 187.904a20.48 20.48 0 0 0-20.48 20.992v141.824A279.04 279.04 0 0 0 153.6 512a252.416 252.416 0 0 0 51.2 51.2 331.776 331.776 0 0 0 95.744 51.2 19.968 19.968 0 0 0 13.312 0zM192 483.84A227.84 227.84 0 0 1 153.6 358.4V247.296a15.872 15.872 0 0 1 15.36-16.384A168.96 168.96 0 0 0 295.936 168.96a13.824 13.824 0 0 1 21.504 0 198.656 198.656 0 0 0 65.536 51.2 142.848 142.848 0 0 0 38.4 10.752 160.256 160.256 0 0 0 22.528 0 15.872 15.872 0 0 1 15.36 13.312v102.4A210.432 210.432 0 0 1 311.296 563.2a15.872 15.872 0 0 1-9.728 0 258.56 258.56 0 0 1-71.68-38.912 218.112 218.112 0 0 1-37.888-40.448z"
        fill={getIconColor(color, 0, '#212121')}
      />
      <path
        d="M780.8 448H870.4V274.432A76.8 76.8 0 0 0 793.6 204.8h-102.4v-3.072A115.2 115.2 0 0 0 576 102.4h-8.192a114.176 114.176 0 0 0-73.728 33.792 71.168 71.168 0 0 1 51.2 24.576 61.952 61.952 0 0 1 30.72-7.68 64 64 0 0 1 64 64.512V256h158.208a25.6 25.6 0 0 1 20.992 25.6v115.2h-38.4A115.2 115.2 0 0 0 665.6 502.784V512a114.688 114.688 0 0 0 105.472 115.712H819.2v114.688a25.6 25.6 0 0 1-25.6 25.6H614.4v38.4a64 64 0 0 1-128 0V768h-153.6a25.6 25.6 0 0 1-25.6-25.6V665.6a75.776 75.776 0 0 1-23.552-4.096 256 256 0 0 1-27.648-10.752v99.328A76.288 76.288 0 0 0 332.8 819.2h102.4v3.072A115.2 115.2 0 0 0 550.4 921.6h7.68a114.688 114.688 0 0 0 107.52-99.328V819.2h128a76.8 76.8 0 0 0 76.8-76.8v-166.4h-95.744A63.488 63.488 0 0 1 716.8 512v-6.144a64.512 64.512 0 0 1 64-57.856z"
        fill={getIconColor(color, 1, '#212121')}
      />
    </svg>
  );
};
/*
A20Regular1.defaultProps = {
  size: 18,
};
*/
export default A20Regular1;
