/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Liefengexian: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1023.960617 89.635937a38.398523 38.398523 0 0 0-38.398523-38.398523h-51.198031A166.3936 166.3936 0 0 0 767.970463 217.631014v588.777355a166.3936 166.3936 0 0 0 166.3936 166.3936h51.198031a38.398523 38.398523 0 0 0 0-76.797046h-51.198031a89.596554 89.596554 0 0 1-89.596554-89.596554V691.2128h140.794585a38.398523 38.398523 0 0 0 0-76.797047H844.767509v-204.792123h140.794585a38.398523 38.398523 0 0 0 0-76.797046H844.767509V217.631014a89.635937 89.635937 0 0 1 89.596554-89.596554h51.198031A38.398523 38.398523 0 0 0 1023.960617 89.635937z m-1023.960617 0C0 68.447829 17.210415 51.237414 38.398523 51.237414h51.198031A166.3936 166.3936 0 0 1 255.990154 217.631014v588.777355A166.3936 166.3936 0 0 1 89.596554 972.801969h-51.198031a38.398523 38.398523 0 0 1 0-76.797046h51.198031a89.596554 89.596554 0 0 0 89.596554-89.596554V691.2128H38.398523a38.398523 38.398523 0 0 1 0-76.797047H179.193108v-204.792123H38.398523a38.398523 38.398523 0 0 1 0-76.797046H179.193108V217.631014A89.596554 89.596554 0 0 0 89.596554 128.03446h-51.198031A38.398523 38.398523 0 0 1 0 89.635937z"
        fill={getIconColor(color, 0, '#3F51B5')}
        opacity=".5"
      />
      <path
        d="M473.581785 344.444598c1.339025 1.181493 2.599285 2.402369 3.898927 3.702012l34.499596 34.499596 34.499597-34.499596a87.430483 87.430483 0 0 1 3.898927-3.702012V38.437906a38.398523 38.398523 0 0 0-76.797047 0v306.006692z m0 347.949695v293.207184a38.398523 38.398523 0 0 0 76.797047 0V692.394293a93.495481 93.495481 0 0 1-3.898927-3.702012L511.980308 654.192685l-34.499596 34.499596-3.898927 3.702012z"
        fill={getIconColor(color, 1, '#3F51B5')}
      />
      <path
        d="M472.597208 315.104188h78.766201v472.597208h-78.766201z"
        fill={getIconColor(color, 2, '#3F51B5')}
      />
    </svg>
  );
};
/*
Liefengexian.defaultProps = {
  size: 18,
};
*/
export default Liefengexian;
