/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDocumentBulletListClock24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M443.42857156 306.28571469V32H169.14285688a91.42857188 91.42857188 0 0 0-91.42857094 91.42857188v731.42857125a91.42857188 91.42857188 0 0 0 91.42857093 91.42857093h311.31428626a296.59428562 296.59428562 0 0 1-74.78857126-137.14285687H386.28571437a34.28571469 34.28571469 0 1 1 1e-8-68.57142844H397.71428563c0-23.58857156 2.74285688-46.53714281 7.95428624-68.57142844H386.28571437a34.28571469 34.28571469 0 1 1 1e-8-68.57142843h44.89142812A297.96571406 297.96571406 0 0 1 480.45714313 534.8571425H386.28571437a34.28571469 34.28571469 0 1 1 1e-8-68.57142844h194.10285749A296.22857156 296.22857156 0 0 1 694.85714281 443.42857156c40.5028575 0 79.08571406 8.09142844 114.28571438 22.76571375V397.71428563h-274.28571469a91.42857188 91.42857188 0 0 1-91.42857094-91.42857094z m-228.57142875 194.28571406a34.28571469 34.28571469 0 1 1 68.57142844 0 34.28571469 34.28571469 0 0 1-68.57142844 0z m0 137.14285687a34.28571469 34.28571469 0 1 1 68.57142844 0 34.28571469 34.28571469 0 0 1-68.57142844 0z m0 137.14285688a34.28571469 34.28571469 0 1 1 68.57142844 0 34.28571469 34.28571469 0 0 1-68.57142844 0zM512 306.28571469V54.8571425l274.28571469 274.28571469H534.8571425a22.8571425 22.8571425 0 0 1-22.8571425-22.8571425z m434.28571406 434.28571406a251.42857125 251.42857125 0 1 0-502.8571425 0 251.42857125 251.42857125 0 0 0 502.8571425 0z m-264.22857093 19.10857125a22.8571425 22.8571425 0 0 1-10.01142844-22.35428531L672.00000031 557.71428594a22.8571425 22.8571425 0 1 1 45.714285 0l0.04571438 159.99999937h114.10285781a22.8571425 22.8571425 0 1 1 0 45.71428594H697.59999969a23.17714313 23.17714313 0 0 1-15.54285657-3.74857125z"
        fill={getIconColor(color, 0, '#707070')}
      />
    </svg>
  );
};
/*
IcFluentDocumentBulletListClock24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentDocumentBulletListClock24Filled;
