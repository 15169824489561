/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentContentSettings24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M757.376 128l7.893333 0.213333a138.666667 138.666667 0 0 1 130.56 130.56l0.213334 7.893334v246.272a275.968 275.968 0 0 0-64-30.250667L832 341.333333H192.042667L192 757.333333a74.666667 74.666667 0 0 0 68.522667 74.410667l6.144 0.256h216.064c7.338667 22.784 17.578667 44.245333 30.208 64H266.666667a138.666667 138.666667 0 0 1-138.453334-130.816L128 757.333333V266.666667a138.666667 138.666667 0 0 1 130.816-138.453334L266.666667 128h490.709333z m0 64h-490.666667a74.666667 74.666667 0 0 0-74.666666 74.666667v10.666666h640v-10.666666a74.666667 74.666667 0 0 0-74.666667-74.666667z m-320.042667 213.333333a32 32 0 0 1 31.701334 27.648l0.298666 4.352v298.666667a32 32 0 0 1-27.648 31.701333L437.333333 768h-149.333333a32 32 0 0 1-31.701333-27.648L256 736v-298.666667a32 32 0 0 1 27.648-31.701333L288 405.333333h149.333333zM405.333333 469.333333h-85.333333v234.666667h85.333333V469.333333z m362.666667-32a32 32 0 0 0-32-32h-191.829333l-4.309334 0.298667A32 32 0 0 0 544.170667 469.333333h191.829333l4.352-0.298666A32 32 0 0 0 768 437.333333z m-158.72 158.933334a85.333333 85.333333 0 0 1-61.525333 106.538666l-24.917334 6.144a244.437333 244.437333 0 0 0 0.256 77.098667l23.04 5.546667a85.333333 85.333333 0 0 1 61.866667 107.093333l-7.978667 26.965333c18.773333 16.469333 40.106667 29.824 63.317334 39.296l21.077333-22.101333a85.333333 85.333333 0 0 1 123.690667 0l21.248 22.4a225.28 225.28 0 0 0 63.274666-38.954667l-8.448-29.269333a85.333333 85.333333 0 0 1 61.525334-106.496l24.874666-6.144a244.437333 244.437333 0 0 0-0.256-77.141333l-23.04-5.546667a85.333333 85.333333 0 0 1-61.866666-107.093333l7.978666-26.88a225.28 225.28 0 0 0-63.317333-39.381334l-21.034667 22.144a85.333333 85.333333 0 0 1-123.733333 0l-21.248-22.4c-23.210667 9.386667-44.544 22.613333-63.274667 38.912l8.448 29.269334zM746.666667 810.666667c-34.133333 0-61.866667-28.672-61.866667-64 0-35.370667 27.733333-64 61.866667-64 34.133333 0 61.866667 28.629333 61.866666 64 0 35.328-27.733333 64-61.866666 64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentContentSettings24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentContentSettings24Regular;
