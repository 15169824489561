/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Chongmanyouce: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M883.2 73.142857C960.950857 73.142857 1024 140.653714 1024 224.036571v575.926858a156.525714 156.525714 0 0 1-41.252571 106.715428c-26.331429 28.306286-62.171429 44.178286-99.547429 44.178286H140.8C63.049143 950.857143 0 883.346286 0 800.036571V223.963429C0 140.653714 63.049143 73.142857 140.8 73.142857z m5.12 795.428572c32.475429 0 58.88-30.72 58.88-68.608V224.109714c0-37.888-26.331429-68.608-58.88-68.608H140.8c-35.328 0-64 30.72-64 68.534857v576.073143c0 37.888 28.672 68.608 64 68.534857z"
        fill={getIconColor(color, 0, '#5B5FC7')}
      />
      <path
        d="M311.076571 155.428571h592.018286c36.571429 0 66.267429 30.72 66.267429 68.608v575.926858c0 37.888-29.696 68.608-66.267429 68.608H311.076571v-713.142858z"
        fill={getIconColor(color, 1, '#5B5FC7')}
        opacity=".204"
      />
    </svg>
  );
};
/*
Chongmanyouce.defaultProps = {
  size: 18,
};
*/
export default Chongmanyouce;
