/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const PersonNote24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M469.333333 640c0-14.933333 2.56-29.312 7.253334-42.666667H181.461333a95.957333 95.957333 0 0 0-95.957333 95.957334v39.253333c0 24.405333 7.637333 48.213333 21.76 68.096C173.098667 892.970667 280.746667 938.666667 426.666667 938.666667c16.938667 0 33.408-0.597333 49.322666-1.834667A127.872 127.872 0 0 1 469.333333 896v-256zM426.666667 85.546667a213.333333 213.333333 0 1 1 0 426.666666 213.333333 213.333333 0 0 1 0-426.666666zM512 640a85.333333 85.333333 0 0 1 85.333333-85.333333h298.666667a85.333333 85.333333 0 0 1 85.333333 85.333333v256a85.333333 85.333333 0 0 1-85.333333 85.333333h-298.666667a85.333333 85.333333 0 0 1-85.333333-85.333333v-256z m106.666667 42.666667a21.333333 21.333333 0 0 0 0 42.666666h256a21.333333 21.333333 0 0 0 0-42.666666h-256z m0 128a21.333333 21.333333 0 0 0 0 42.666666h256a21.333333 21.333333 0 0 0 0-42.666666h-256z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
PersonNote24Filled.defaultProps = {
  size: 18,
};
*/
export default PersonNote24Filled;
