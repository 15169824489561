/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSlideLayout24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M288 298.666667A74.666667 74.666667 0 0 0 213.333333 373.333333v277.333334c0 41.216 33.450667 74.666667 74.666667 74.666666h448A74.666667 74.666667 0 0 0 810.666667 650.666667v-277.333334A74.666667 74.666667 0 0 0 736 298.666667H288zM277.333333 373.333333a10.666667 10.666667 0 0 1 10.666667-10.666666h448a10.666667 10.666667 0 0 1 10.666667 10.666666v74.666667h-469.333334V373.333333z m170.666667 138.666667h298.666667v138.666667a10.666667 10.666667 0 0 1-10.666667 10.666666H448V512zM384 512v149.333333H288a10.666667 10.666667 0 0 1-10.666667-10.666666V512H384z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M202.666667 170.666667A117.333333 117.333333 0 0 0 85.333333 288v448A117.333333 117.333333 0 0 0 202.666667 853.333333h618.666666A117.333333 117.333333 0 0 0 938.666667 736V288A117.333333 117.333333 0 0 0 821.333333 170.666667H202.666667zM149.333333 288c0-29.44 23.893333-53.333333 53.333334-53.333333h618.666666c29.44 0 53.333333 23.893333 53.333334 53.333333v448c0 29.44-23.893333 53.333333-53.333334 53.333333H202.666667c-29.44 0-53.333333-23.893333-53.333334-53.333333V288z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentSlideLayout24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentSlideLayout24Regular;
