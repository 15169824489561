/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A50: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M877.714286 73.142857a73.142857 73.142857 0 0 1 73.142857 73.142857V877.714286a73.142857 73.142857 0 0 1-73.142857 73.142857H602.550857C552.521143 950.784 512 876.544 512 784.822857V151.405714c0-2.925714 0-5.778286 0.146286-8.630857L512 146.358857a73.142857 73.142857 0 0 1 73.142857-73.142857h292.571429z"
        fill={getIconColor(color, 0, '#5B5FC7')}
        opacity=".204"
      />
      <path
        d="M883.2 73.142857C960.950857 73.142857 1024 140.653714 1024 224.036571v575.926858a156.525714 156.525714 0 0 1-41.252571 106.715428c-26.331429 28.306286-62.171429 44.178286-99.547429 44.178286H140.8C63.049143 950.857143 0 883.346286 0 800.036571V223.963429C0 140.653714 63.049143 73.142857 140.8 73.142857z m-16.969143 795.428572c44.690286 0 80.969143-30.72 80.969143-68.608V224.109714c0-37.888-36.278857-68.608-80.969143-68.608H140.8c-35.328 0-64 30.72-64 68.534857v576.073143c0 37.888 28.672 68.608 64 68.534857z"
        fill={getIconColor(color, 1, '#5B5FC7')}
      />
    </svg>
  );
};
/*
A50.defaultProps = {
  size: 18,
};
*/
export default A50;
