/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Filled1X: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M589.696 277.333333h252.970667a96 96 0 0 1 95.104 82.688l0.682666 6.741334 0.213334 6.570666v130.56A277.333333 277.333333 0 0 0 512.938667 853.333333H181.333333a96 96 0 0 1-95.786666-89.429333L85.333333 757.333333v-309.376l264.832 0.042667 8.362667-0.384a96 96 0 0 0 46.421333-16.768l6.656-5.12L589.696 277.333333zM350.165333 170.666667c19.626667 0 38.741333 6.016 54.784 17.152l6.656 5.12 89.728 74.709333-130.688 108.928-3.626666 2.602667a32 32 0 0 1-12.373334 4.522666L350.122667 384 85.333333 383.957333V266.666667a96 96 0 0 1 89.429334-95.786667L181.333333 170.666667h168.832zM512 704a234.666667 234.666667 0 1 0 469.333333 0 234.666667 234.666667 0 0 0-469.333333 0z m362.666667-149.333333a21.333333 21.333333 0 0 1 21.333333 21.333333v85.333333a21.333333 21.333333 0 0 1-21.333333 21.333334h-85.333334a21.333333 21.333333 0 0 1 0-42.666667h42.666667a106.496 106.496 0 0 0-85.333333-42.666667c-35.541333 0-67.072 17.365333-86.485334 44.202667a21.333333 21.333333 0 0 1-34.56-25.002667A149.162667 149.162667 0 0 1 746.666667 554.666667c41.813333 0 79.573333 17.194667 106.666666 44.8v-23.466667a21.333333 21.333333 0 0 1 21.333334-21.333333zM640 808.533333v23.466667a21.333333 21.333333 0 0 1-42.666667 0v-85.333333a21.333333 21.333333 0 0 1 21.333334-21.333334h85.333333a21.333333 21.333333 0 0 1 0 42.666667h-42.666667c19.456 25.941333 50.474667 42.666667 85.333334 42.666667 32.682667 0 61.952-14.677333 81.536-37.888a21.333333 21.333333 0 0 1 32.597333 27.52A149.034667 149.034667 0 0 1 746.666667 853.333333a148.906667 148.906667 0 0 1-106.666667-44.8z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
A24Filled1X.defaultProps = {
  size: 18,
};
*/
export default A24Filled1X;
