/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentNotebookError24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M277.333333 512a234.666667 234.666667 0 1 1 0 469.333333 234.666667 234.666667 0 0 1 0-469.333333zM714.624 85.333333a96 96 0 0 1 96 96v661.333334a96 96 0 0 1-96 96h-237.226667a275.797333 275.797333 0 0 0 45.994667-63.957334l191.232-0.042666a32 32 0 0 0 32-32V181.333333a32 32 0 0 0-32-32H266.666667a32 32 0 0 0-32 32v291.242667a275.498667 275.498667 0 0 0-64 18.005333V181.333333A96 96 0 0 1 266.666667 85.333333h447.957333zM277.333333 848.213333a26.666667 26.666667 0 1 0 0 53.290667 26.666667 26.666667 0 0 0 0-53.290667z m0-250.752a21.333333 21.333333 0 0 0-20.992 17.493334l-0.341333 3.84v170.581333l0.341333 3.84a21.333333 21.333333 0 0 0 41.984 0L298.666667 789.333333v-170.581333l-0.341334-3.84a21.333333 21.333333 0 0 0-20.992-17.493333z m608 42.624a32 32 0 0 1 31.701334 27.648l0.298666 4.352v63.914667a32 32 0 0 1-27.648 31.701333L885.333333 768H853.333333v-127.914667h32z m0-170.666666a32 32 0 0 1 31.701334 27.648l0.298666 4.352v63.914666a32 32 0 0 1-27.648 31.701334L885.333333 597.333333H853.333333v-127.914666h32z m0-170.666667a32 32 0 0 1 31.701334 27.648l0.298666 4.352V394.666667a32 32 0 0 1-27.648 31.701333L885.333333 426.666667H853.333333V298.752h32zM650.624 213.333333a32 32 0 0 1 32 32v106.581334a32 32 0 0 1-32 32H330.666667a32 32 0 0 1-32-32V245.333333A32 32 0 0 1 330.666667 213.333333h319.957333z m-32 64H362.666667v42.581334h255.957333V277.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentNotebookError24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentNotebookError24Regular;
