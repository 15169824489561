/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const DataPie24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M437.333333 181.333333A32 32 0 0 1 469.333333 213.333333v341.333334h341.333334a32 32 0 0 1 31.701333 27.648l0.298667 4.352c0 212.053333-171.946667 362.666667-384 362.666666a384 384 0 0 1-384-384c0-212.053333 150.613333-384 362.666666-384z m128-106.666666a384 384 0 0 1 384 384 32 32 0 0 1-32 32h-352a32 32 0 0 1-32-32V106.666667a32 32 0 0 1 32-32z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
DataPie24Filled.defaultProps = {
  size: 18,
};
*/
export default DataPie24Filled;
