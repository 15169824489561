/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCloudBackup24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M259.712 330.666667a245.418667 245.418667 0 0 1 483.242667 0h3.712a170.666667 170.666667 0 0 1 169.045333 194.218666 278.784 278.784 0 0 0-68.138667-58.197333A106.709333 106.709333 0 0 0 746.666667 394.666667h-32.256a32 32 0 0 1-32-30.421334 181.333333 181.333333 0 0 0-362.197334 0 32 32 0 0 1-31.957333 30.421334H256a106.666667 106.666667 0 0 0 0 213.333333h187.733333a275.541333 275.541333 0 0 0-15.232 64H256a170.666667 170.666667 0 0 1 0-341.333333h3.712zM938.666667 704a234.666667 234.666667 0 1 1-469.333334 0 234.666667 234.666667 0 0 1 469.333334 0z m-256-76.501333V832a21.333333 21.333333 0 0 0 42.666666 0v-204.501333l70.229334 70.272a21.333333 21.333333 0 0 0 30.208-30.208l-106.666667-106.666667a21.333333 21.333333 0 0 0-30.208 0l-106.666667 106.666667a21.333333 21.333333 0 0 0 30.208 30.208L682.666667 627.498667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentCloudBackup24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentCloudBackup24Regular;
