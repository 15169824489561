/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ChannelArrowLeft24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M746.666667 512a234.666667 234.666667 0 1 1 0 469.333333 234.666667 234.666667 0 0 1 0-469.333333z m10.666666-384a138.666667 138.666667 0 0 1 138.453334 130.816L896 266.666667v246.272A276.053333 276.053333 0 0 0 746.666667 469.333333c-78.72 0-149.76 32.810667-200.234667 85.461334h-173.226667l-4.266666 0.298666a32 32 0 0 0 0 63.402667l4.266666 0.298667h127.317334A276.138667 276.138667 0 0 0 469.333333 746.666667c0 55.04 15.957333 106.24 43.605334 149.376L266.666667 896a138.666667 138.666667 0 0 1-138.453334-130.816L128 757.333333V399.872a96 96 0 1 0 9.557333-183.893333 138.794667 138.794667 0 0 1 120.789334-87.722667L266.666667 128h490.666666z m-43.946666 494.464l-2.986667 2.432-107.306667 107.392-1.749333 2.133333-1.877333 3.285334-0.981334 2.517333-0.810666 3.626667-0.085334 4.693333 0.64 3.669333 1.237334 3.413334 1.450666 2.602666 2.133334 2.816 107.349333 107.392a21.333333 21.333333 0 0 0 32.64-27.221333l-2.474667-2.986667L670.336 768h204.373333a21.333333 21.333333 0 0 0 20.949334-17.493333L896 746.666667a21.333333 21.333333 0 0 0-17.493333-20.992L874.666667 725.333333h-204.330667l70.229333-70.229333a21.333333 21.333333 0 0 0 2.474667-27.221333l-2.474667-2.986667a21.333333 21.333333 0 0 0-27.221333-2.432z m-62.634667-217.301333H373.248l-4.352 0.298666a32 32 0 0 0 0 63.402667l4.352 0.298667h277.504l4.352-0.298667a32 32 0 0 0-4.352-63.701333zM160 256a53.333333 53.333333 0 1 1 0 106.666667 53.333333 53.333333 0 0 1 0-106.666667z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
ChannelArrowLeft24Filled.defaultProps = {
  size: 18,
};
*/
export default ChannelArrowLeft24Filled;
