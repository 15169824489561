/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCheckmarkCircle24Regular: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m0 64a362.666667 362.666667 0 1 0 0 725.333334 362.666667 362.666667 0 0 0 0-725.333334z m-53.333333 424.106667l190.72-190.72a32 32 0 0 1 48.341333 41.642667l-3.114667 3.584-213.333333 213.333333a32 32 0 0 1-41.642667 3.114667l-3.584-3.114667-106.666666-106.666667a32 32 0 0 1 41.642666-48.341333l3.584 3.114667 84.053334 84.053333 190.72-190.72-190.72 190.72z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentCheckmarkCircle24Regular.defaultProps = {
  size: 18,
};
*/
export default IcFluentCheckmarkCircle24Regular;
