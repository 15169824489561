/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Male: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M409.6 486.4l197.12-2.56v102.4L409.6 588.8z"
        fill={getIconColor(color, 0, '#F3E8BA')}
      />
      <path
        d="M698.88 281.6c2.56-56.32-2.56-94.72-10.24-117.76-12.8-35.84-51.2-53.76-64-53.76s-28.16-48.64-135.68-53.76c-40.96-2.56-120.32 23.04-153.6 61.44-53.76 58.88-43.52 148.48-43.52 166.4-7.68 15.36-25.6 71.68 30.72 89.6 0 40.96 20.48 115.2 79.36 133.12 33.28 10.24 79.36 12.8 115.2 12.8 89.6-2.56 110.08-25.6 122.88-43.52 23.04-25.6 35.84-89.6 35.84-102.4 43.52-15.36 48.64-46.08 23.04-92.16z"
        fill={getIconColor(color, 1, '#FFF7D6')}
      />
      <path
        d="M404.48 578.56h212.48c117.76 0 217.6 84.48 240.64 199.68l10.24 46.08c12.8 69.12-30.72 135.68-102.4 151.04H281.6c-71.68 0-128-56.32-128-128 0-7.68 0-15.36 2.56-23.04l7.68-48.64c23.04-115.2 122.88-197.12 240.64-197.12z"
        fill={getIconColor(color, 2, '#7697FF')}
      />
      <path
        d="M381.44 578.56L460.8 852.48h102.4l79.36-273.92c-20.48-2.56-84.48-2.56-133.12-2.56-51.2-2.56-102.4 0-128 2.56z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
      <path
        d="M460.8 573.44l25.6 61.44-25.6 227.84h102.4l-23.04-227.84 23.04-58.88-56.32-2.56z"
        fill={getIconColor(color, 4, '#7697FF')}
      />
      <path
        d="M701.44 286.72l-2.56-5.12c2.56-56.32-2.56-94.72-10.24-117.76-12.8-35.84-51.2-53.76-64-53.76s-28.16-48.64-135.68-53.76c-40.96-2.56-120.32 23.04-153.6 61.44-53.76 58.88-43.52 148.48-43.52 166.4 0 2.56-2.56 7.68-2.56 10.24 15.36 5.12 48.64 12.8 89.6 10.24 79.36-2.56 148.48-12.8 186.88-76.8-2.56 0 58.88 79.36 135.68 58.88z"
        fill={getIconColor(color, 5, '#5A6274')}
      />
    </svg>
  );
};
/*
Male.defaultProps = {
  size: 18,
};
*/
export default Male;
