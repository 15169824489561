/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BookQuestionMark24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 192v640A106.666667 106.666667 0 0 0 277.333333 938.666667h565.333334a32 32 0 0 0 0-64H277.333333a42.666667 42.666667 0 0 1-42.666666-42.666667h608a32 32 0 0 0 32-32V192A106.666667 106.666667 0 0 0 768 85.333333H277.333333A106.666667 106.666667 0 0 0 170.666667 192z m298.666666 150.101333a32 32 0 0 1-32 31.232c-32 0-32-32.042667-32-32.042666V340.906667a59.861333 59.861333 0 0 1 0.341334-5.717334 94.805333 94.805333 0 0 1 17.92-45.525333c19.370667-26.154667 54.186667-45.312 110.293333-44.330667 40.533333 0.725333 76.501333 17.706667 99.029333 45.653334 22.912 28.458667 30.634667 66.986667 15.445334 104.917333-15.445333 38.613333-50.389333 53.973333-70.485334 62.762667l-2.133333 0.981333c-11.946667 5.248-17.621333 7.978667-21.034667 10.709333l-0.042666 0.042667v30.890667a32 32 0 0 1-64 0.042666V469.333333c0-22.314667 10.752-38.272 24.021333-48.938666 10.666667-8.533333 24.106667-14.421333 33.536-18.602667l1.621333-0.725333c23.125333-10.197333 34.133333-16.512 39.125334-28.970667a39.253333 39.253333 0 0 0-5.888-40.96c-9.472-11.733333-26.837333-21.418667-50.304-21.802667-39.893333-0.682667-53.12 12.16-57.728 18.432a30.805333 30.805333 0 0 0-5.717334 14.08v0.256z m96 276.565334a42.666667 42.666667 0 1 1-85.333333 0 42.666667 42.666667 0 0 1 85.333333 0z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};
/*
BookQuestionMark24Filled.defaultProps = {
  size: 18,
};
*/
export default BookQuestionMark24Filled;
