/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentNews24Filled: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M714.666667 170.666667a96 96 0 0 1 95.786666 89.429333L810.666667 266.666667V746.666667a21.333333 21.333333 0 0 0 42.325333 3.84L853.333333 746.666667V299.264a96 96 0 0 1 85.077334 88.405333L938.666667 394.666667v320a138.666667 138.666667 0 0 1-130.816 138.453333L800 853.333333H224a138.666667 138.666667 0 0 1-138.453333-130.816L85.333333 714.666667V266.666667a96 96 0 0 1 89.429334-95.786667L181.333333 170.666667h533.333334z m-320.085334 298.666666h-149.333333a32 32 0 0 0-32 32v149.333334c0 17.664 14.336 32 32 32h149.333333a32 32 0 0 0 32-32v-149.333334a32 32 0 0 0-32-32z m256.170667 149.333334h-106.581333l-4.352 0.298666A32 32 0 0 0 544.170667 682.666667h106.581333l4.352-0.298667a32 32 0 0 0-4.352-63.701333z m-288.170667-85.333334v85.333334h-85.333333v-85.333334h85.333333zM650.666667 469.333333l-106.581334 0.213334-4.352 0.256a32 32 0 0 0 4.437334 63.744l106.624-0.213334 4.309333-0.298666A32 32 0 0 0 650.709333 469.333333z m0.042666-149.162666H245.248l-4.352 0.298666a32 32 0 0 0 4.352 63.701334h405.504l4.352-0.256a32 32 0 0 0-4.352-63.744z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
IcFluentNews24Filled.defaultProps = {
  size: 18,
};
*/
export default IcFluentNews24Filled;
