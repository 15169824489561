/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Filled14: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M554.666667 85.333333a105.386667 105.386667 0 0 1 105.386666 105.386667l-0.042666 22.613333H810.666667a42.666667 42.666667 0 0 1 42.666666 42.666667l-0.042666 149.290667-65.28 0.042666a105.386667 105.386667 0 0 0-105.130667 98.432L682.666667 510.72v2.56a105.386667 105.386667 0 0 0 98.432 105.173333l6.954666 0.213334 65.237334-0.042667 0.042666 149.504a42.666667 42.666667 0 0 1-42.666666 42.666667l-150.656-0.042667v22.528a105.386667 105.386667 0 1 1-210.730667 0v-22.528H298.666667a42.666667 42.666667 0 0 1-42.666667-42.666667l-0.042667-150.656h-22.613333a105.386667 105.386667 0 0 1 0-210.773333H256L256 256a42.666667 42.666667 0 0 1 42.666667-42.666667h150.613333v-22.613333A105.386667 105.386667 0 0 1 554.666667 85.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};
/*
A24Filled14.defaultProps = {
  size: 18,
};
*/
export default A24Filled14;
