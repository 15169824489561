/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Regular101: FunctionComponent<Props> = ({ size=18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M576 437.333333a32.426667 32.426667 0 0 0-32-32h-277.333333a32 32 0 0 0 0 64h277.333333a32.426667 32.426667 0 0 0 32-32zM266.666667 533.333333a32 32 0 0 0 0 64h234.666666a32 32 0 0 0 0-64zM768 490.666667a106.666667 106.666667 0 1 0 106.666667 106.666666 106.666667 106.666667 0 0 0-106.666667-106.666666z m0 149.333333a42.666667 42.666667 0 1 1 42.666667-42.666667 42.666667 42.666667 0 0 1-42.666667 42.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M202.666667 725.333333A74.666667 74.666667 0 0 1 128 650.666667v-405.333334A74.666667 74.666667 0 0 1 202.666667 170.666667h576A74.666667 74.666667 0 0 1 853.333333 245.333333v229.546667A148.48 148.48 0 0 1 917.333333 597.333333V245.333333a139.093333 139.093333 0 0 0-138.666666-138.666666H512v-10.666667a32 32 0 0 0-64 0v10.666667H202.666667A139.093333 139.093333 0 0 0 64 245.333333v405.333334a139.093333 139.093333 0 0 0 138.666667 138.666666h102.4l-63.146667 75.52a32 32 0 0 0 49.493333 42.666667L388.266667 789.333333h149.76a119.893333 119.893333 0 0 1 45.653333-64zM884.48 746.666667h-232.96a75.52 75.52 0 0 0-75.52 75.52v4.693333c0 66.56 55.04 133.12 192 133.12s192-66.986667 192-133.12v-4.693333a75.52 75.52 0 0 0-75.52-75.52z m11.52 80.213333c0 42.666667-49.066667 69.12-128 69.12s-128-26.453333-128-69.12v-4.693333a11.52 11.52 0 0 1 11.52-11.52h232.96a11.52 11.52 0 0 1 11.52 11.52z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M266.666667 341.333333h170.666666a32 32 0 0 0 0-64h-170.666666a32 32 0 0 0 0 64z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};
/*
A24Regular101.defaultProps = {
  size: 18,
};
*/
export default A24Regular101;
